import axiosInstance, {
  chunkArray,
  chunkByNestedArraySize,
} from '@app/utils/axiosInstance';
import type {
  Adjustment,
  SharedInstructionAdjustment,
  SharedInstructionIncreaseToResponse,
  SharedInstructionDecreaseToResponse,
  AccountGroupAdjustment,
  SharedInstructionAccountGroupDecreaseToResponse,
  SharedInstructionAccountGroupIncreaseToResponse,
  SharedInstructionAccountGroupAdjustment,
} from '@bsa/shared-types';
import { AxiosResponse } from 'axios';

const CHUNK_SIZE = 100;

export async function createIncreaseToInstruction(
  adjustment: SharedInstructionAdjustment,
  proposals: Adjustment[],
): Promise<SharedInstructionIncreaseToResponse> {
  const chunks = chunkArray(proposals, CHUNK_SIZE);

  const promises = chunks.map((proposalChunk) =>
    axiosInstance.post(`/instructions/increaseto`, {
      adjustment: adjustment,
      proposals: proposalChunk,
    }),
  );

  // Wait for all the requests to resolve
  const responses = await Promise.all(promises);

  return responses.reduce(
    (
      data: SharedInstructionIncreaseToResponse,
      response: AxiosResponse<SharedInstructionIncreaseToResponse>,
    ) => {
      data.proposals.push(...response.data.proposals);

      return data;
    },
    { proposals: [] },
  );
}

export async function createDecreaseToInstruction(
  adjustment: SharedInstructionAdjustment,
  proposals: Adjustment[],
): Promise<SharedInstructionDecreaseToResponse> {
  const chunks = chunkArray(proposals, CHUNK_SIZE);

  const promises = chunks.map((proposalChunk) =>
    axiosInstance.post(`/instructions/decreaseto`, {
      adjustment: adjustment,
      proposals: proposalChunk,
    }),
  );

  // Wait for all the requests to resolve
  const responses = await Promise.all(promises);

  return responses.reduce(
    (
      data: SharedInstructionDecreaseToResponse,
      response: AxiosResponse<SharedInstructionDecreaseToResponse>,
    ) => {
      data.proposals.push(...response.data.proposals);

      return data;
    },
    { proposals: [] },
  );
}

export async function createDecreaseToInstructionForAccountGroups(
  adjustment: SharedInstructionAccountGroupAdjustment,
  proposals: AccountGroupAdjustment[],
): Promise<SharedInstructionAccountGroupDecreaseToResponse> {
  const chunks = chunkByNestedArraySize(proposals, 'accounts', CHUNK_SIZE);

  const promises = chunks.map((proposalChunk) =>
    axiosInstance.post(`/instructions/accountgroup/decreaseto`, {
      adjustment: adjustment,
      proposals: proposalChunk,
    }),
  );

  // Wait for all the requests to resolve
  const responses = await Promise.all(promises);

  return responses.reduce(
    (
      data: SharedInstructionAccountGroupDecreaseToResponse,
      response: AxiosResponse<SharedInstructionAccountGroupDecreaseToResponse>,
    ) => {
      data.proposals.push(...response.data.proposals);

      return data;
    },
    { proposals: [] },
  );
}

export async function createIncreaseToInstructionForAccountGroups(
  adjustment: SharedInstructionAccountGroupAdjustment,
  proposals: AccountGroupAdjustment[],
): Promise<SharedInstructionAccountGroupIncreaseToResponse> {
  const chunks = chunkByNestedArraySize(proposals, 'accounts', CHUNK_SIZE);

  const promises = chunks.map((proposalChunk) =>
    axiosInstance.post(`/instructions/accountgroup/increaseto`, {
      adjustment: adjustment,
      proposals: proposalChunk,
    }),
  );

  // Wait for all the requests to resolve
  const responses = await Promise.all(promises);

  return responses.reduce(
    (
      data: SharedInstructionAccountGroupIncreaseToResponse,
      response: AxiosResponse<SharedInstructionAccountGroupIncreaseToResponse>,
    ) => {
      data.proposals.push(...response.data.proposals);

      return data;
    },
    { proposals: [] },
  );
}
