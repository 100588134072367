import axiosInstance from '@app/utils/axiosInstance';
import type {
  SharedAccountAdjustmentDetails,
  SharedProposalRequestPayload,
  SharedProposalResponse,
} from '@bsa/shared-types';

export async function createAccountAdjustmentOnProposal(
  securityId: number,
  accountAdjustments: SharedAccountAdjustmentDetails[],
): Promise<SharedProposalResponse> {
  const response = await axiosInstance.post(`/proposals`, {
    security_id: securityId,
    adjustments: accountAdjustments,
  });
  return response.data;
}

export async function createAccountSwitchAdjustmentOnProposal(
  body: SharedProposalRequestPayload[],
): Promise<SharedProposalResponse> {
  const response = await axiosInstance.post(`/proposals/switch`, body);
  return response.data;
}
