import { IressText } from '@iress/components-react';

function SwitchPageTitle() {
  return (
    <>
      <IressText element={IressText.Element.H2} noGutter>
        Bulk security actions 2 of 2
      </IressText>
      <IressText element={IressText.Element.Span} noGutter>
        Apply instructions and make adjustments to switch from the selected
        security
      </IressText>
    </>
  );
}

export default SwitchPageTitle;
