import {
  AccountGroupAdjustment,
  Adjustment,
  AllocationType,
  InstructionAdjustmentType,
  SharedInstructionAccountGroupDecreaseToResponse,
  SharedInstructionAccountGroupIncreaseToResponse,
  SharedInstructionDecreaseToResponse,
  SharedInstructionIncreaseToResponse,
  sharedMockServiceType,
} from '@bsa/shared-types';

export const mockInstructionsIncreaseToRequest = {
  adjustment: {
    type: InstructionAdjustmentType.IncreasePercentTo,
    value: '20',
  },
  proposals: [
    {
      portfolio: 'Johnson, Jon',
      accountGroup: 'ALPHA',
      accountGroupCode: 'ALPHA',
      account: 'account-description-1',
      externalAccountId: 'account-code-1',
      instrument: 'VINZAVOD ASENOV - VINZAVOD ASENOVG ORD SHS',
      SEDOL: '',
      ISIN: 'BG11VIASAT18',
      security: '0IPI.LSE',
      'marketPrice (CCY)': '£249.93',
      nativePrice: '0.31',
      nativeCurrency: 'BGN',
      assetClassShortName: 'EMERGING MARKETS EQUITY',
      'currentValue (CCY)': '£11,118.27',
      'targetValue (CCY)': '£0.00',
      'proposedValue (CCY)': '£0.00',
      targetNativeValue: '£0.00',
      proposedNativeValue: '£0.00',
      currentUnits: '5,343.78',
      targetUnits: '0',
      proposedUnits: '0',
      currentWeight: '1.00%',
      targetWeight: '0.00%',
      proposedWeight: '0.00%',
      'currentCashAvailable (CCY)': '0.00%',
      'proposedCashAvailable (CCY)': '0.00%',
      'currentCash %': '0.00%',
      'assetClass %': '0.00%',
      taxType: 'tbc',
      'taxableGain/Loss (CCY)': '-£974.55',
      'gain/Loss (CCY)': '-£974.55',
      status: 'tbc',
      accountId: '123456',
      portfolioValue: '£11,2610.40',
      serviceType: sharedMockServiceType.Discretionary,
    },
    {
      portfolio: 'Johnson, Jane',
      accountGroup: 'ALPHA',
      accountGroupCode: 'ALPHA',
      account: 'account-description-2',
      externalAccountId: 'account-code-2',
      instrument: 'VINZAVOD ASENOV - VINZAVOD ASENOVG ORD SHS',
      SEDOL: '',
      ISIN: 'BG11VIASAT18',
      security: '0IPI.LSE',
      'marketPrice (CCY)': '£249.93',
      nativePrice: '0.31',
      nativeCurrency: 'BGN',
      assetClassShortName: 'EMERGING MARKETS EQUITY',
      'currentValue (CCY)': '£0.00',
      'targetValue (CCY)': '£0.00',
      'proposedValue (CCY)': '£0.00',
      targetNativeValue: '£0.00',
      proposedNativeValue: '£0.00',
      currentUnits: '0',
      targetUnits: '0',
      proposedUnits: '0',
      currentWeight: '0.00%',
      targetWeight: '0.00%',
      proposedWeight: '0.00%',
      'currentCashAvailable (CCY)': '0.00%',
      'proposedCashAvailable (CCY)': '0.00%',
      'currentCash %': '0.00%',
      'assetClass %': '0.00%',
      taxType: 'tbc',
      'taxableGain/Loss (CCY)': '£0.00',
      'gain/Loss (CCY)': '£0.00',
      status: 'tbc',
      accountId: '123457',
      portfolioValue: '£13569.50',
      serviceType: 'Execution Only',
    },
  ] as Adjustment[],
};

export const mockInstructionIncreaseToResponse: SharedInstructionIncreaseToResponse =
  {
    proposals: [
      {
        portfolio: 'Johnson, Jon',
        accountGroup: 'ALPHA',
        accountGroupCode: 'ALPHA',
        account: 'account-description-1',
        externalAccountId: 'account-code-1',
        instrument: 'VINZAVOD ASENOV - VINZAVOD ASENOVG ORD SHS',
        SEDOL: '',
        ISIN: 'BG11VIASAT18',
        security: '0IPI.LSE',
        'marketPrice (CCY)': '£249.93',
        nativePrice: '0.31',
        nativeCurrency: 'BGN',
        assetClassShortName: 'EMERGING MARKETS EQUITY',
        'currentValue (CCY)': '£11,118.27',
        'targetValue (CCY)': '£11,261.40',
        'proposedValue (CCY)': '£143.13',
        targetNativeValue: '£0.00',
        proposedNativeValue: '£0.00',
        currentUnits: '5,343.78',
        targetUnits: '5,805.48',
        proposedUnits: '461.70',
        currentWeight: '1.00%',
        targetWeight: '20.00%',
        proposedWeight: '8.64%',
        'currentCashAvailable (CCY)': '0.00%',
        'proposedCashAvailable (CCY)': '0.00%',
        'currentCash %': '0.00%',
        'assetClass %': '0.00%',
        taxType: 'tbc',
        'taxableGain/Loss (CCY)': '-£974.55',
        'gain/Loss (CCY)': '-£974.55',
        status: 'tbc',
        accountId: '123456',
        portfolioValue: '£11,2610.40',
        serviceType: sharedMockServiceType.Discretionary,
        hierarchy: ['ALPHA', 'account-description-1'],
      },
      {
        portfolio: 'Johnson, Jane',
        accountGroup: 'ALPHA',
        accountGroupCode: 'ALPHA',
        account: 'account-description-2',
        externalAccountId: 'account-code-2',
        instrument: 'VINZAVOD ASENOV - VINZAVOD ASENOVG ORD SHS',
        SEDOL: '',
        ISIN: 'BG11VIASAT18',
        security: '0IPI.LSE',
        'marketPrice (CCY)': '£249.93',
        nativePrice: '0.31',
        nativeCurrency: 'BGN',
        assetClassShortName: 'EMERGING MARKETS EQUITY',
        'currentValue (CCY)': '£0.00',
        'targetValue (CCY)': '£271.39',
        'proposedValue (CCY)': '£271.39',
        targetNativeValue: '£0.00',
        proposedNativeValue: '£0.00',
        currentUnits: '0',
        targetUnits: '875.46',
        proposedUnits: '875.46',
        currentWeight: '0.00%',
        targetWeight: '20.00%',
        proposedWeight: '20.00%',
        'currentCashAvailable (CCY)': '0.00%',
        'proposedCashAvailable (CCY)': '0.00%',
        'currentCash %': '0.00%',
        'assetClass %': '0.00%',
        taxType: 'tbc',
        'taxableGain/Loss (CCY)': '£0.00',
        'gain/Loss (CCY)': '£0.00',
        status: 'tbc',
        accountId: '123457',
        portfolioValue: '£13569.50',
        serviceType: 'Execution Only',
        hierarchy: ['ALPHA', 'account-description-2'],
      },
    ],
  };

export const mockInstructionsDecreaseToRequest = {
  adjustment: {
    type: InstructionAdjustmentType.DecreasePercentTo,
    value: '20',
  },
  proposals: [
    {
      portfolio: 'Johnson, Jon',
      accountGroup: 'ALPHA',
      accountGroupCode: 'ALPHA',
      account: 'account-description-1',
      externalAccountId: 'account-code-1',
      instrument: 'VINZAVOD ASENOV - VINZAVOD ASENOVG ORD SHS',
      SEDOL: '',
      ISIN: 'BG11VIASAT18',
      security: '0IPI.LSE',
      'marketPrice (CCY)': '£249.93',
      nativePrice: '0.31',
      nativeCurrency: 'BGN',
      assetClassShortName: 'EMERGING MARKETS EQUITY',
      'currentValue (CCY)': '£11,118.27',
      'targetValue (CCY)': '£0.00',
      'proposedValue (CCY)': '£0.00',
      targetNativeValue: '£0.00',
      proposedNativeValue: '£0.00',
      currentUnits: '5,343.78',
      targetUnits: '0',
      proposedUnits: '0',
      currentWeight: '1.00%',
      targetWeight: '0.00%',
      proposedWeight: '0.00%',
      'currentCashAvailable (CCY)': '0.00%',
      'proposedCashAvailable (CCY)': '0.00%',
      'currentCash %': '0.00%',
      'assetClass %': '0.00%',
      taxType: 'tbc',
      'taxableGain/Loss (CCY)': '-£974.55',
      'gain/Loss (CCY)': '-£974.55',
      status: 'tbc',
      accountId: '123456',
      portfolioValue: '£11,2610.40',
      serviceType: sharedMockServiceType.Discretionary,
    },
    {
      portfolio: 'Johnson, Jane',
      accountGroup: 'ALPHA',
      accountGroupCode: 'ALPHA',
      account: 'account-description-2',
      externalAccountId: 'account-code-2',
      instrument: 'VINZAVOD ASENOV - VINZAVOD ASENOVG ORD SHS',
      SEDOL: '',
      ISIN: 'BG11VIASAT18',
      security: '0IPI.LSE',
      'marketPrice (CCY)': '£249.93',
      nativePrice: '0.31',
      nativeCurrency: 'BGN',
      assetClassShortName: 'EMERGING MARKETS EQUITY',
      'currentValue (CCY)': '£0.00',
      'targetValue (CCY)': '£0.00',
      'proposedValue (CCY)': '£0.00',
      targetNativeValue: '£0.00',
      proposedNativeValue: '£0.00',
      currentUnits: '0',
      targetUnits: '0',
      proposedUnits: '0',
      currentWeight: '0.00%',
      targetWeight: '0.00%',
      proposedWeight: '0.00%',
      'currentCashAvailable (CCY)': '0.00%',
      'proposedCashAvailable (CCY)': '0.00%',
      'currentCash %': '0.00%',
      'assetClass %': '0.00%',
      taxType: 'tbc',
      'taxableGain/Loss (CCY)': '£0.00',
      'gain/Loss (CCY)': '£0.00',
      status: 'tbc',
      accountId: '123457',
      portfolioValue: '£13569.50',
      serviceType: 'Execution Only',
    },
  ] as Adjustment[],
};

export const mockInstructionDecreaseToResponse: SharedInstructionDecreaseToResponse =
  {
    proposals: [
      {
        portfolio: 'Johnson, Jon',
        accountGroup: 'ALPHA',
        accountGroupCode: 'ALPHA',
        account: 'account-description-1',
        externalAccountId: 'account-code-1',
        instrument: 'VINZAVOD ASENOV - VINZAVOD ASENOVG ORD SHS',
        SEDOL: '',
        ISIN: 'BG11VIASAT18',
        security: '0IPI.LSE',
        'marketPrice (CCY)': '£249.93',
        nativePrice: '0.31',
        nativeCurrency: 'BGN',
        assetClassShortName: 'EMERGING MARKETS EQUITY',
        'currentValue (CCY)': '£11,118.27',
        'targetValue (CCY)': '£11,261.40',
        'proposedValue (CCY)': '£143.13',
        targetNativeValue: '£0.00',
        proposedNativeValue: '£0.00',
        currentUnits: '5,343.78',
        targetUnits: '5,805.48',
        proposedUnits: '461.70',
        currentWeight: '1.00%',
        targetWeight: '20.00%',
        proposedWeight: '8.64%',
        'currentCashAvailable (CCY)': '0.00%',
        'proposedCashAvailable (CCY)': '0.00%',
        'currentCash %': '0.00%',
        'assetClass %': '0.00%',
        taxType: 'tbc',
        'taxableGain/Loss (CCY)': '-£974.55',
        'gain/Loss (CCY)': '-£974.55',
        status: 'tbc',
        accountId: '123456',
        portfolioValue: '£11,2610.40',
        serviceType: sharedMockServiceType.Discretionary,
        hierarchy: ['ALPHA', 'account-description-1'],
      },
      {
        portfolio: 'Johnson, Jane',
        accountGroup: 'ALPHA',
        accountGroupCode: 'ALPHA',
        account: 'account-description-2',
        externalAccountId: 'account-code-2',
        instrument: 'VINZAVOD ASENOV - VINZAVOD ASENOVG ORD SHS',
        SEDOL: '',
        ISIN: 'BG11VIASAT18',
        security: '0IPI.LSE',
        'marketPrice (CCY)': '£249.93',
        nativePrice: '0.31',
        nativeCurrency: 'BGN',
        assetClassShortName: 'EMERGING MARKETS EQUITY',
        'currentValue (CCY)': '£0.00',
        'targetValue (CCY)': '£271.39',
        'proposedValue (CCY)': '£271.39',
        targetNativeValue: '£0.00',
        proposedNativeValue: '£0.00',
        currentUnits: '0',
        targetUnits: '875.46',
        proposedUnits: '875.46',
        currentWeight: '0.00%',
        targetWeight: '20.00%',
        proposedWeight: '20.00%',
        'currentCashAvailable (CCY)': '0.00%',
        'proposedCashAvailable (CCY)': '0.00%',
        'currentCash %': '0.00%',
        'assetClass %': '0.00%',
        taxType: 'tbc',
        'taxableGain/Loss (CCY)': '£0.00',
        'gain/Loss (CCY)': '£0.00',
        status: 'tbc',
        accountId: '123457',
        portfolioValue: '£13569.50',
        serviceType: 'Execution Only',
        hierarchy: ['ALPHA', 'account-description-2'],
      },
    ],
  };

export const mockInstructionsAccountGroupDecreaseToRequest = {
  adjustment: {
    type: InstructionAdjustmentType.DecreasePercentTo,
    value: '10',
    allocationType: AllocationType.ProRataDecreaseTo,
  },
  proposals: [
    {
      accountGroup: {
        portfolio: 'Johnson, Jane',
        accountGroup: 'ALPHA',
        accountGroupCode: 'ALPHA',
        account: '',
        externalAccountId: '',
        instrument: '',
        SEDOL: '',
        ISIN: '',
        security: '',
        'marketPrice (CCY)': '100.00',
        nativePrice: '0.31',
        nativeCurrency: 'BGN',
        assetClassShortName: 'EMERGING MARKETS EQUITY',
        'currentValue (CCY)': '1000.00',
        'targetValue (CCY)': '0.00',
        'proposedValue (CCY)': '0.00',
        targetNativeValue: '0.00',
        proposedNativeValue: '0.00',
        currentUnits: '10',
        targetUnits: '0',
        proposedUnits: '0',
        currentWeight: '20.00',
        targetWeight: '0.00',
        proposedWeight: '0.00',
        'currentCashAvailable (CCY)': '0.00',
        'proposedCashAvailable (CCY)': '0.00',
        'currentCash %': '0.00',
        'assetClass %': '0.00',
        taxType: 'tbc',
        'taxableGain/Loss (CCY)': '0.00',
        'gain/Loss (CCY)': '0.00',
        status: 'tbc',
        accountId: '',
        portfolioValue: '5000.00',
        serviceType: '',
        hierarchy: ['ALPHA', ''],
      },
      accounts: [
        {
          portfolio: 'Johnson, Jane',
          accountGroup: 'ALPHA',
          accountGroupCode: 'ALPHA',
          account: 'account-description-1',
          externalAccountId: 'account-code-1',
          instrument: 'VINZAVOD ASENOV - VINZAVOD ASENOVG ORD SHS',
          SEDOL: '',
          ISIN: 'BG11VIASAT18',
          security: '0IPI.LSE',
          'marketPrice (CCY)': '100.00',
          nativePrice: '0.31',
          nativeCurrency: 'BGN',
          assetClassShortName: 'EMERGING MARKETS EQUITY',
          'currentValue (CCY)': '200.00',
          'targetValue (CCY)': '0.00',
          'proposedValue (CCY)': '0.00',
          targetNativeValue: '0.00',
          proposedNativeValue: '0.00',
          currentUnits: '2',
          targetUnits: '0',
          proposedUnits: '0',
          currentWeight: '20.00',
          targetWeight: '0.00',
          proposedWeight: '0.00',
          'currentCashAvailable (CCY)': '0.00',
          'proposedCashAvailable (CCY)': '0.00',
          'currentCash %': '0.00',
          'assetClass %': '0.00',
          taxType: 'tbc',
          'taxableGain/Loss (CCY)': '0.00',
          'gain/Loss (CCY)': '0.00',
          status: 'tbc',
          accountId: '123457',
          portfolioValue: '500.00',
          serviceType: sharedMockServiceType.Discretionary,
          hierarchy: ['ALPHA', 'account-description-1'],
        },
        {
          portfolio: 'Johnson, Jane',
          accountGroup: 'ALPHA',
          accountGroupCode: 'ALPHA',
          account: 'account-description-2',
          externalAccountId: 'account-code-2',
          instrument: 'VINZAVOD ASENOV - VINZAVOD ASENOVG ORD SHS',
          SEDOL: '',
          ISIN: 'BG11VIASAT18',
          security: '0IPI.LSE',
          'marketPrice (CCY)': '100.00',
          nativePrice: '0.31',
          nativeCurrency: 'BGN',
          assetClassShortName: 'EMERGING MARKETS EQUITY',
          'currentValue (CCY)': '800.00',
          'targetValue (CCY)': '0.00',
          'proposedValue (CCY)': '0.00',
          targetNativeValue: '0.00',
          proposedNativeValue: '0.00',
          currentUnits: '8',
          targetUnits: '0',
          proposedUnits: '0',
          currentWeight: '10.00',
          targetWeight: '0.00',
          proposedWeight: '0.00',
          'currentCashAvailable (CCY)': '0.00',
          'proposedCashAvailable (CCY)': '0.00',
          'currentCash %': '0.00',
          'assetClass %': '0.00',
          taxType: 'tbc',
          'taxableGain/Loss (CCY)': '0.00',
          'gain/Loss (CCY)': '0.00',
          status: 'tbc',
          accountId: '123457',
          portfolioValue: '8000.00',
          serviceType: 'Execution Only',
          hierarchy: ['ALPHA', 'account-description-2'],
        },
      ],
    },
  ] as AccountGroupAdjustment[],
};

export const mockInstructionAccountGroupDecreaseToResponse: SharedInstructionAccountGroupDecreaseToResponse =
  {
    proposals: [
      {
        accountGroup: {
          portfolio: 'Johnson, Jane',
          accountGroup: 'ALPHA',
          accountGroupCode: 'ALPHA',
          account: '',
          externalAccountId: '',
          instrument: '',
          SEDOL: '',
          ISIN: '',
          security: '',
          'marketPrice (CCY)': '100.00',
          nativePrice: '0.31',
          nativeCurrency: 'BGN',
          assetClassShortName: 'EMERGING MARKETS EQUITY',
          'currentValue (CCY)': '1000.00',
          'targetValue (CCY)': '500.00',
          'proposedValue (CCY)': '-500.00',
          targetNativeValue: '0.00',
          proposedNativeValue: '0.00',
          currentUnits: '10',
          targetUnits: '5',
          proposedUnits: '-5',
          currentWeight: '20.00',
          targetWeight: '10.00',
          proposedWeight: '-10.00',
          'currentCashAvailable (CCY)': '0.00',
          'proposedCashAvailable (CCY)': '0.00',
          'currentCash %': '0.00',
          'assetClass %': '0.00',
          taxType: 'tbc',
          'taxableGain/Loss (CCY)': '0.00',
          'gain/Loss (CCY)': '0.00',
          status: 'tbc',
          accountId: '',
          portfolioValue: '5000.00',
          serviceType: '',
          hierarchy: ['ALPHA', ''],
        },
        accounts: [
          {
            portfolio: 'Johnson, Jane',
            accountGroup: 'ALPHA',
            accountGroupCode: 'ALPHA',
            account: 'account-description-1',
            externalAccountId: 'account-code-1',
            instrument: 'VINZAVOD ASENOV - VINZAVOD ASENOVG ORD SHS',
            SEDOL: '',
            ISIN: 'BG11VIASAT18',
            security: '0IPI.LSE',
            'marketPrice (CCY)': '100.00',
            nativePrice: '0.31',
            nativeCurrency: 'BGN',
            assetClassShortName: 'EMERGING MARKETS EQUITY',
            'currentValue (CCY)': '200.00',
            'targetValue (CCY)': '100.00',
            'proposedValue (CCY)': '-100.00',
            targetNativeValue: '0.00',
            proposedNativeValue: '0.00',
            currentUnits: '2',
            targetUnits: '1',
            proposedUnits: '-1',
            currentWeight: '20.00',
            targetWeight: '10.00',
            proposedWeight: '-10.00',
            'currentCashAvailable (CCY)': '0.00',
            'proposedCashAvailable (CCY)': '0.00',
            'currentCash %': '0.00',
            'assetClass %': '0.00',
            taxType: 'tbc',
            'taxableGain/Loss (CCY)': '0.00',
            'gain/Loss (CCY)': '0.00',
            status: 'tbc',
            accountId: '123457',
            portfolioValue: '500.00',
            serviceType: sharedMockServiceType.Discretionary,
            hierarchy: ['ALPHA', 'account-description-1'],
          },
          {
            portfolio: 'Johnson, Jane',
            accountGroup: 'ALPHA',
            accountGroupCode: 'ALPHA',
            account: 'account-description-2',
            externalAccountId: 'account-code-2',
            instrument: 'VINZAVOD ASENOV - VINZAVOD ASENOVG ORD SHS',
            SEDOL: '',
            ISIN: 'BG11VIASAT18',
            security: '0IPI.LSE',
            'marketPrice (CCY)': '100.00',
            nativePrice: '0.31',
            nativeCurrency: 'BGN',
            assetClassShortName: 'EMERGING MARKETS EQUITY',
            'currentValue (CCY)': '800.00',
            'targetValue (CCY)': '400.00',
            'proposedValue (CCY)': '-400.00',
            targetNativeValue: '0.00',
            proposedNativeValue: '0.00',
            currentUnits: '8',
            targetUnits: '4',
            proposedUnits: '-4',
            currentWeight: '10.00',
            targetWeight: '5.00',
            proposedWeight: '-5.00',
            'currentCashAvailable (CCY)': '0.00',
            'proposedCashAvailable (CCY)': '0.00',
            'currentCash %': '0.00',
            'assetClass %': '0.00',
            taxType: 'tbc',
            'taxableGain/Loss (CCY)': '0.00',
            'gain/Loss (CCY)': '0.00',
            status: 'tbc',
            accountId: '123457',
            portfolioValue: '8000.00',
            serviceType: 'Execution Only',
            hierarchy: ['ALPHA', 'account-description-2'],
          },
        ],
      },
    ],
  };

export const mockInstructionsAccountGroupIncreaseToRequest = {
  adjustment: {
    type: InstructionAdjustmentType.IncreasePercentTo,
    value: '10',
    allocationType: AllocationType.ProRataIncreaseTo,
  },
  proposals: [
    {
      accountGroup: {
        portfolio: 'Johnson, Jane',
        accountGroup: 'ALPHA',
        accountGroupCode: 'ALPHA',
        account: '',
        externalAccountId: '',
        instrument: '',
        SEDOL: '',
        ISIN: '',
        security: '',
        'marketPrice (CCY)': '100.00',
        nativePrice: '0.31',
        nativeCurrency: 'BGN',
        assetClassShortName: 'EMERGING MARKETS EQUITY',
        'currentValue (CCY)': '1000.00',
        'targetValue (CCY)': '0.00',
        'proposedValue (CCY)': '0.00',
        targetNativeValue: '0.00',
        proposedNativeValue: '0.00',
        currentUnits: '10',
        targetUnits: '0',
        proposedUnits: '0',
        currentWeight: '20.00',
        targetWeight: '0.00',
        proposedWeight: '0.00',
        'currentCashAvailable (CCY)': '0.00',
        'proposedCashAvailable (CCY)': '0.00',
        'currentCash %': '0.00',
        'assetClass %': '0.00',
        taxType: 'tbc',
        'taxableGain/Loss (CCY)': '0.00',
        'gain/Loss (CCY)': '0.00',
        status: 'tbc',
        accountId: '',
        portfolioValue: '5000.00',
        serviceType: '',
        hierarchy: ['ALPHA', ''],
      },
      accounts: [
        {
          portfolio: 'Johnson, Jane',
          accountGroup: 'ALPHA',
          accountGroupCode: 'ALPHA',
          account: 'account-description-1',
          externalAccountId: 'account-code-1',
          instrument: 'VINZAVOD ASENOV - VINZAVOD ASENOVG ORD SHS',
          SEDOL: '',
          ISIN: 'BG11VIASAT18',
          security: '0IPI.LSE',
          'marketPrice (CCY)': '100.00',
          nativePrice: '0.31',
          nativeCurrency: 'BGN',
          assetClassShortName: 'EMERGING MARKETS EQUITY',
          'currentValue (CCY)': '200.00',
          'targetValue (CCY)': '0.00',
          'proposedValue (CCY)': '0.00',
          targetNativeValue: '0.00',
          proposedNativeValue: '0.00',
          currentUnits: '2',
          targetUnits: '0',
          proposedUnits: '0',
          currentWeight: '20.00',
          targetWeight: '0.00',
          proposedWeight: '0.00',
          'currentCashAvailable (CCY)': '0.00',
          'proposedCashAvailable (CCY)': '0.00',
          'currentCash %': '0.00',
          'assetClass %': '0.00',
          taxType: 'tbc',
          'taxableGain/Loss (CCY)': '0.00',
          'gain/Loss (CCY)': '0.00',
          status: 'tbc',
          accountId: '123457',
          portfolioValue: '500.00',
          serviceType: sharedMockServiceType.Discretionary,
          hierarchy: ['ALPHA', 'account-description-1'],
        },
        {
          portfolio: 'Johnson, Jane',
          accountGroup: 'ALPHA',
          accountGroupCode: 'ALPHA',
          account: 'account-description-2',
          externalAccountId: 'account-code-2',
          instrument: 'VINZAVOD ASENOV - VINZAVOD ASENOVG ORD SHS',
          SEDOL: '',
          ISIN: 'BG11VIASAT18',
          security: '0IPI.LSE',
          'marketPrice (CCY)': '100.00',
          nativePrice: '0.31',
          nativeCurrency: 'BGN',
          assetClassShortName: 'EMERGING MARKETS EQUITY',
          'currentValue (CCY)': '800.00',
          'targetValue (CCY)': '0.00',
          'proposedValue (CCY)': '0.00',
          targetNativeValue: '0.00',
          proposedNativeValue: '0.00',
          currentUnits: '8',
          targetUnits: '0',
          proposedUnits: '0',
          currentWeight: '10.00',
          targetWeight: '0.00',
          proposedWeight: '0.00',
          'currentCashAvailable (CCY)': '0.00',
          'proposedCashAvailable (CCY)': '0.00',
          'currentCash %': '0.00',
          'assetClass %': '0.00',
          taxType: 'tbc',
          'taxableGain/Loss (CCY)': '0.00',
          'gain/Loss (CCY)': '0.00',
          status: 'tbc',
          accountId: '123457',
          portfolioValue: '8000.00',
          serviceType: 'Execution Only',
          hierarchy: ['ALPHA', 'account-description-2'],
        },
      ],
    },
  ] as AccountGroupAdjustment[],
};

export const mockInstructionsAccountGroupIncreaseToResponse: SharedInstructionAccountGroupIncreaseToResponse =
  {
    proposals: [
      {
        accountGroup: {
          portfolio: 'Johnson, Jane',
          accountGroup: 'ALPHA',
          accountGroupCode: 'ALPHA',
          account: '',
          externalAccountId: '',
          instrument: '',
          SEDOL: '',
          ISIN: '',
          security: '',
          'marketPrice (CCY)': '100.00',
          nativePrice: '0.31',
          nativeCurrency: 'BGN',
          assetClassShortName: 'EMERGING MARKETS EQUITY',
          'currentValue (CCY)': '1000.00',
          'targetValue (CCY)': '500.00',
          'proposedValue (CCY)': '-500.00',
          targetNativeValue: '0.00',
          proposedNativeValue: '0.00',
          currentUnits: '10',
          targetUnits: '5',
          proposedUnits: '-5',
          currentWeight: '20.00',
          targetWeight: '10.00',
          proposedWeight: '-10.00',
          'currentCashAvailable (CCY)': '0.00',
          'proposedCashAvailable (CCY)': '0.00',
          'currentCash %': '0.00',
          'assetClass %': '0.00',
          taxType: 'tbc',
          'taxableGain/Loss (CCY)': '0.00',
          'gain/Loss (CCY)': '0.00',
          status: 'tbc',
          accountId: '',
          portfolioValue: '5000.00',
          serviceType: '',
          hierarchy: ['ALPHA', ''],
        },
        accounts: [
          {
            portfolio: 'Johnson, Jane',
            accountGroup: 'ALPHA',
            accountGroupCode: 'ALPHA',
            account: 'account-description-1',
            externalAccountId: 'account-code-1',
            instrument: 'VINZAVOD ASENOV - VINZAVOD ASENOVG ORD SHS',
            SEDOL: '',
            ISIN: 'BG11VIASAT18',
            security: '0IPI.LSE',
            'marketPrice (CCY)': '100.00',
            nativePrice: '0.31',
            nativeCurrency: 'BGN',
            assetClassShortName: 'EMERGING MARKETS EQUITY',
            'currentValue (CCY)': '200.00',
            'targetValue (CCY)': '100.00',
            'proposedValue (CCY)': '-100.00',
            targetNativeValue: '0.00',
            proposedNativeValue: '0.00',
            currentUnits: '2',
            targetUnits: '1',
            proposedUnits: '-1',
            currentWeight: '20.00',
            targetWeight: '10.00',
            proposedWeight: '-10.00',
            'currentCashAvailable (CCY)': '0.00',
            'proposedCashAvailable (CCY)': '0.00',
            'currentCash %': '0.00',
            'assetClass %': '0.00',
            taxType: 'tbc',
            'taxableGain/Loss (CCY)': '0.00',
            'gain/Loss (CCY)': '0.00',
            status: 'tbc',
            accountId: '123457',
            portfolioValue: '500.00',
            serviceType: sharedMockServiceType.Discretionary,
            hierarchy: ['ALPHA', 'account-description-1'],
          },
          {
            portfolio: 'Johnson, Jane',
            accountGroup: 'ALPHA',
            accountGroupCode: 'ALPHA',
            account: 'account-description-2',
            externalAccountId: 'account-code-2',
            instrument: 'VINZAVOD ASENOV - VINZAVOD ASENOVG ORD SHS',
            SEDOL: '',
            ISIN: 'BG11VIASAT18',
            security: '0IPI.LSE',
            'marketPrice (CCY)': '100.00',
            nativePrice: '0.31',
            nativeCurrency: 'BGN',
            assetClassShortName: 'EMERGING MARKETS EQUITY',
            'currentValue (CCY)': '800.00',
            'targetValue (CCY)': '400.00',
            'proposedValue (CCY)': '-400.00',
            targetNativeValue: '0.00',
            proposedNativeValue: '0.00',
            currentUnits: '8',
            targetUnits: '4',
            proposedUnits: '-4',
            currentWeight: '10.00',
            targetWeight: '5.00',
            proposedWeight: '-5.00',
            'currentCashAvailable (CCY)': '0.00',
            'proposedCashAvailable (CCY)': '0.00',
            'currentCash %': '0.00',
            'assetClass %': '0.00',
            taxType: 'tbc',
            'taxableGain/Loss (CCY)': '0.00',
            'gain/Loss (CCY)': '0.00',
            status: 'tbc',
            accountId: '123457',
            portfolioValue: '8000.00',
            serviceType: 'Execution Only',
            hierarchy: ['ALPHA', 'account-description-2'],
          },
        ],
      },
    ],
  };
