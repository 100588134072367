import { createAccountSwitchAdjustmentOnProposal } from '@app/services/proposals';
import { createSwitchInstruction } from '@app/services/switch';
import {
  SharedProposalRequestPayload,
  SharedSwitchPayload,
} from '@bsa/shared-types';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const createSwitchInstructionThunk = createAsyncThunk(
  'Switch/createSwitchInstruction',
  async ({ sellSecurity, buySecurities, adjustments }: SharedSwitchPayload) => {
    return createSwitchInstruction(sellSecurity, buySecurities, adjustments);
  },
);

export const createSwitchProposalThunk = createAsyncThunk(
  'Switch/createSwitchProposal',
  async (payload: SharedProposalRequestPayload[]) => {
    return createAccountSwitchAdjustmentOnProposal(payload);
  },
);
