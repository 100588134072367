import { UNLEASH_TOGGLES } from '@app/app/unleash';
import { useFlag } from '@unleash/proxy-client-react';
import { SwitchForm } from '@app/components/SwitchForm';
import {
  IressButton,
  IressCol,
  IressPanel,
  IressRow,
  IressStack,
  showModal,
} from '@iress/components-react';
import { InstructionsPageTitle } from '@app/components/Titles';
import { ButtonPrimary } from '@app/components/Button';
import { Modal } from '@app/components/Modal';
import { AgGridReact } from 'ag-grid-react';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { CtaPanel } from '@app/components/CtaPanel';
import { useAppDispatch, useAppSelector } from '@app/app/hooks';
import { AppDispatch, RootState } from '@app/app/store';
import { SharedSecurityDetailsResponse } from '@bsa/shared-types';
import { setAdjustmentProposalResponseStatus } from '@app/features/AccountGroups';
import ButtonExit from '@app/components/Button/ButtonExit';
import { SAVE_SESSION_MODAL_ID } from '@app/app/App';
import { handleGenerateProposalStatus } from './InstructionsPage.utils';
import { useInstructionsPage } from './InstructionsPage';

const generateProposalErrorModalId = 'generate-proposal-error-modal-ok';

export const useSwitchPage = (
  security: SharedSecurityDetailsResponse,
  dispatch: AppDispatch,
  gridRef: RefObject<AgGridReact>,
  setShowValidationErrorMessage: (value: boolean) => void,
  setValidationErrorMessage: (value: string) => void,
  setIsLoading: (value: boolean) => void,
) => {
  const handleOnClick = useInstructionsPage(
    security,
    dispatch,
    gridRef,
    setShowValidationErrorMessage,
    setValidationErrorMessage,
    setIsLoading,
  );
  const handleOnExit = useCallback(() => {}, []);
  return {
    handleOnClick,
    handleOnExit,
    setShowValidationErrorMessage,
    setIsLoading,
  };
};

function SwitchPage() {
  const dispatch = useAppDispatch();
  const [validationErrorMessage] = useState<string>('');
  const adjustmentProposalResponses = useAppSelector(
    (state: RootState) => state.accountGroups.adjustmentProposalResponses,
  );
  const gridRef = useRef<AgGridReact>(null);
  const [showValidationErrorMessage] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isSaveProgressEnabled = useFlag(UNLEASH_TOGGLES.SaveProgress);
  const isSwitchEnabled = useFlag(UNLEASH_TOGGLES.Switch);

  const okModalButtons = [
    <ButtonPrimary key="OK" buttonText="OK" link="/" dataTestid="btn-ok" />,
  ];

  useEffect(() => {
    handleGenerateProposalStatus(
      adjustmentProposalResponses.loading,
      setIsLoading,
      isSaveProgressEnabled,
      generateProposalErrorModalId,
    );
    return () => {
      dispatch(setAdjustmentProposalResponseStatus('idle'));
    };
  }, [adjustmentProposalResponses.loading, dispatch, isSaveProgressEnabled]);

  return (
    <div
      style={{
        height: '100%',
        flexDirection: 'column',
        display: 'flex',
        gap: '8px',
      }}
    >
      {isSwitchEnabled && (
        <>
          <IressRow>
            <IressCol>
              <InstructionsPageTitle />
            </IressCol>
            <IressCol>
              <CtaPanel
                selectedRowCount="0"
                totalRowCount="0"
                selectedLevel="account"
                showErrorMessage={showValidationErrorMessage}
                errorMessage={validationErrorMessage}
              >
                {isSaveProgressEnabled && (
                  <ButtonExit
                    onClick={() => showModal(SAVE_SESSION_MODAL_ID, true)}
                  />
                )}
                <IressButton
                  key="generateProposals"
                  mode={IressButton.Mode.Primary}
                  type={IressButton.Type.Button}
                  data-testid="primary-btn"
                  onClick={() => {}}
                  loading={isLoading}
                >
                  Generate Proposals
                </IressButton>
              </CtaPanel>
            </IressCol>
          </IressRow>
          <IressPanel
            padding={IressPanel.Padding.Sm}
            background={IressPanel.Background.Default}
          >
            <IressStack gutter={IressStack.Gutter.Md}>
              <SwitchForm gridRef={gridRef} />
            </IressStack>
          </IressPanel>
          <Modal
            id={generateProposalErrorModalId}
            dataTestId={generateProposalErrorModalId}
            title="Something went wrong"
            message="Your progress has been saved. Please try again later."
            buttons={okModalButtons}
          />
        </>
      )}
    </div>
  );
}
export default SwitchPage;
