import { RefObject, useCallback, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Adjustment, AccountGroupAdjustment } from '@bsa/shared-types';
import { showModal } from '@iress/components-react';
import { useInstructionState } from './useInstructionState';
import { applyInstructionModalId } from '../ApplyInstructionModal';
import { useInstructionHandlers } from './useInstructionHandlers';
import { useGridInteractions } from './useGridInteractions';
import { useModalHandling } from './useModalHandling';
import { Instructions } from '@app/components/Select/InstructionsOptions';
import { ViewLevels } from '../constants';
import { useAppDispatch } from '@app/app/hooks';
import {
  setInstructionsApplied,
  setInstructionsChanged,
  setPreviousExcludedServiceTypes,
  setPreviousInputtedPercentage,
  setPreviousSelectedAllocation,
  setPreviousSelectedInstruction,
} from '@app/features/Instructions';

interface UseInstructionSubmissionParams {
  gridRef: RefObject<AgGridReact>;
  instructionState: ReturnType<typeof useInstructionState>;
}

export const useInstructionSubmission = ({
  gridRef,
  instructionState,
}: UseInstructionSubmissionParams) => {
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const {
    uiState: {
      viewLevel,
      isSaveProgressEnabled,
      instructionsChanged,
      instructionsApplied,
    },
    currentValues: {
      originalProposals,
      selectedInstruction,
      inputtedPercentage,
      selectedAllocation,
      excludedServiceTypes,
    },
  } = instructionState;

  const {
    handleSellAllInstruction,
    handleIncreasePositionInstruction,
    handleDecreasePositionInstruction,
  } = useInstructionHandlers(viewLevel);

  const { updateGridData, selectAllRows, filterAndSelectRowsByServiceType } =
    useGridInteractions({ gridRef, viewLevel, excludedServiceTypes });

  const { handleError } = useModalHandling({
    isSaveProgressEnabled,
    instructionsChanged,
  });

  const canProceedWithSubmission = useCallback(() => {
    return !(!gridRef.current || !selectedInstruction);
  }, [gridRef, selectedInstruction]);

  const handleInstruction = useCallback(
    async (proposals: (Adjustment | AccountGroupAdjustment)[]) => {
      switch (selectedInstruction) {
        case Instructions.sellAll.option: {
          return await handleSellAllInstruction(proposals);
        }
        case Instructions.increase.option: {
          return await handleIncreasePositionInstruction(
            proposals,
            inputtedPercentage,
            selectedAllocation,
            excludedServiceTypes,
          );
        }
        case Instructions.decrease.option: {
          return await handleDecreasePositionInstruction(
            proposals,
            inputtedPercentage,
          );
        }
        default: {
          return viewLevel === ViewLevels.accountGroups
            ? { proposals: [] as AccountGroupAdjustment[] }
            : { proposals: [] as Adjustment[] };
        }
      }
    },
    [
      excludedServiceTypes,
      handleDecreasePositionInstruction,
      handleIncreasePositionInstruction,
      handleSellAllInstruction,
      inputtedPercentage,
      selectedAllocation,
      selectedInstruction,
      viewLevel,
    ],
  );

  const getProposals = useCallback(
    () => originalProposals.map((node) => node),
    [originalProposals],
  );

  const handleSubmitAsync = useCallback(async () => {
    if (!canProceedWithSubmission()) return;
    const proposals = getProposals();
    setIsLoaderVisible(true);

    try {
      const updatedProposals = await handleInstruction(proposals);
      updateGridData(updatedProposals);
      selectAllRows();
      if (selectedInstruction === Instructions.increase.option) {
        filterAndSelectRowsByServiceType();
      }
      dispatch(setInstructionsApplied(true));
      dispatch(setInstructionsChanged(false));
    } catch {
      handleError();
    } finally {
      setIsLoaderVisible(false);
    }
  }, [
    canProceedWithSubmission,
    getProposals,
    handleInstruction,
    updateGridData,
    selectAllRows,
    selectedInstruction,
    dispatch,
    filterAndSelectRowsByServiceType,
    handleError,
  ]);

  const handleFormOnSubmitEvent = useCallback(() => {
    if (instructionsApplied && instructionsChanged) {
      showModal(applyInstructionModalId, true);
    } else {
      handleSubmitAsync().catch((error) => {
        console.error('Error:', error);
      });
    }
  }, [handleSubmitAsync, instructionsApplied, instructionsChanged]);

  const handleApplyNewInstruction = useCallback(() => {
    dispatch(setPreviousSelectedInstruction(selectedInstruction));
    dispatch(setPreviousInputtedPercentage(inputtedPercentage));
    dispatch(setPreviousSelectedAllocation(selectedAllocation));
    dispatch(setPreviousExcludedServiceTypes(excludedServiceTypes));
    handleSubmitAsync().catch((error) => {
      console.error('Error:', error);
    });
    showModal(applyInstructionModalId, false);
  }, [
    dispatch,
    excludedServiceTypes,
    handleSubmitAsync,
    inputtedPercentage,
    selectedAllocation,
    selectedInstruction,
  ]);

  return {
    handleFormOnSubmitEvent,
    handleApplyNewInstruction,
    isLoaderVisible,
    setIsLoaderVisible,
  };
};
