import { useAppSelector } from '@app/app/hooks';
import { RootState } from '@app/app/store';
import {
  InputKeyboardEvent,
  IressField,
  IressInput,
} from '@iress/components-react';

function InputPercentage(
  props: Readonly<{
    handleChange: (event: InputKeyboardEvent) => void;
  }>,
) {
  const inputPercentage = useAppSelector(
    (state: RootState) => state.instructions.inputtedPercentage,
  );
  return (
    <IressField
      label="Percentage"
      key="percentage"
      data-testid="percentage-label"
      valueMissing="Please enter a percentage"
      patternMismatch="Please enter a value between 0.00 and 100.00"
    >
      <IressInput
        type={IressInput.Type.Text}
        data-testid="percentage-input"
        width={IressInput.Width.Four}
        required
        pattern="^(100\.0|100\.00|100)|([0-9]{1,2}){1}(\.[0-9]{1,2}){0,2}$"
        maxlength={6}
        onChange={props.handleChange}
        value={inputPercentage}
      >
        <span slot={IressInput.Slot.Append}>%</span>
      </IressInput>
    </IressField>
  );
}
export default InputPercentage;
