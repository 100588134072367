import { http, HttpResponse } from 'msw';
import {
  mockSecuritiesDetailsResponse,
  mockSecurityMarketPriceResponse,
} from './securities.mocks';
import { mockFormattingResponse } from './sysadmin.mocks';
import { mockProposalsResponse } from './proposals.mock';
import { sharedMockInstructionAccountGroupDecreaseToResponse } from '../../../shared-types/src/mocks/adjustments/index';
import {
  mockInstructionDecreaseByResponse,
  mockInstructionIncreaseToResponse,
  mockInstructionAccountGroupDecreaseByResponse,
  mockInstructionAccountGroupIncreaseToResponse,
} from './instructions.mock';
import {
  sharedMockAccountGroupManualAdjustmentResponse,
  sharedMockAccountGroupsViewResponse,
  sharedMockAccountsViewResponse,
  sharedMockInstructionDecreaseToResponse,
  sharedMockManualAdjustmentResponse,
} from '@bsa/shared-types';
import { compressData } from '@app/utils';

export const handlers = [
  http.get('services/primary-xpp-bsa/api/securities', () => {
    return HttpResponse.json(mockSecuritiesDetailsResponse);
  }),
  http.get('services/primary-xpp-bsa/api/test', () => {
    return HttpResponse.json({ data: 'test data' });
  }),
  http.get('services/primary-xpp-bsa/api/accounts', ({ request }) => {
    const url = new URL(request.url);
    const viewLevel = url.searchParams.get('viewLevel');
    return HttpResponse.json(
      viewLevel === 'accounts'
        ? sharedMockAccountsViewResponse
        : sharedMockAccountGroupsViewResponse,
    );
  }),
  http.get('services/primary-xpp-bsa/api/securities/:securityId', () => {
    return HttpResponse.json(mockSecurityMarketPriceResponse);
  }),
  http.get('services/primary-xpp-bsa/api/sysadmin/formatting', () => {
    return HttpResponse.json(mockFormattingResponse);
  }),
  http.post('services/primary-xpp-bsa/api/proposals', () => {
    return HttpResponse.json(mockProposalsResponse);
  }),
  http.post('services/primary-xpp-bsa/api/instructions/decreaseby', () => {
    return HttpResponse.json(mockInstructionDecreaseByResponse);
  }),
  http.post('services/primary-xpp-bsa/api/instructions/increaseto', () => {
    return HttpResponse.json(mockInstructionIncreaseToResponse);
  }),
  http.post('services/primary-xpp-bsa/api/instructions/decreaseto', () => {
    return HttpResponse.json(sharedMockInstructionDecreaseToResponse);
  }),
  http.post(
    'services/primary-xpp-bsa/api/instructions/accountgroup/decreaseby',
    () => {
      return HttpResponse.json(mockInstructionAccountGroupDecreaseByResponse);
    },
  ),
  http.post(
    'services/primary-xpp-bsa/api/instructions/accountgroup/decreaseto',
    () => {
      return HttpResponse.json(
        sharedMockInstructionAccountGroupDecreaseToResponse,
      );
    },
  ),
  http.post(
    'services/primary-xpp-bsa/api/instructions/accountgroup/increaseto',
    () => {
      return HttpResponse.json(mockInstructionAccountGroupIncreaseToResponse);
    },
  ),

  http.post(
    'services/primary-xpp-bsa/api/manual-adjustments/account/adjust',
    () => {
      return HttpResponse.json(sharedMockManualAdjustmentResponse);
    },
  ),
  http.post(
    'services/primary-xpp-bsa/api/manual-adjustments/accountgroup/adjust',
    () => {
      return HttpResponse.json(sharedMockAccountGroupManualAdjustmentResponse);
    },
  ),
  http.post('services/primary-xpp-bsa/api/save-progress', () => {
    return HttpResponse.json('progress saved');
  }),
  http.get('services/primary-xpp-bsa/api/save-progress', () => {
    return HttpResponse.json(compressData('progress data'));
  }),
  http.delete('services/primary-xpp-bsa/api/save-progress', () => {
    return HttpResponse.json('progress deleted');
  }),
];
