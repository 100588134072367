import {
  IressCol,
  IressForm,
  IressRow,
  IressInline,
  ButtonMode,
} from '@iress/components-react';
import { RefObject } from 'react';
import { SelectInstruction } from '../Select';
import { InputUnitPrice } from '../Input';
import { ButtonSubmit } from '../Button';
import { AgGridReact } from 'ag-grid-react';
import { useInstructionSubmission } from '../InstructionsForm/hooks/useInstructionSubmission';
import { useInstructionState } from '../InstructionsForm/hooks/useInstructionState';
import ApplyInstructionModal from '../InstructionsForm/ApplyInstructionModal';
import ApplyInstructionErrorModal from '../InstructionsForm/ApplyInstructionErrorModal';

interface Props {
  gridRef: RefObject<AgGridReact>;
}

const SwitchForm = ({ gridRef }: Readonly<Props>) => {
  const instructionState = useInstructionState();
  const {
    uiState: { selectedLevel, showUnitPrice },
    handlers: { handleInstructionChange },
  } = instructionState;

  const {
    handleFormOnSubmitEvent,
    handleApplyNewInstruction,
    isLoaderVisible,
  } = useInstructionSubmission({
    gridRef,
    instructionState,
  });

  return (
    <IressForm
      data-testid="switch-form"
      disabledClearOnSubmit
      hiddenErrorSummary
      onSubmit={handleFormOnSubmitEvent}
    >
      <IressRow
        gutter={IressRow.Gutter.Lg}
        verticalAlign={IressRow.VerticalAlign.Bottom}
      >
        <IressCol span={IressCol.Span.Three}>
          <SelectInstruction
            handleChange={handleInstructionChange}
            selectedLevel={selectedLevel}
          />
        </IressCol>
        <IressCol>
          <IressInline
            gutter={IressInline.Gutter.Lg}
            verticalAlign={IressInline.VerticalAlign.Bottom}
          >
            {showUnitPrice && <InputUnitPrice />}
            <ButtonSubmit
              key="apply"
              buttonText="Apply"
              dataTestId="submit-btn"
              mode={ButtonMode.Primary}
              isLoaderVisible={isLoaderVisible}
            />
          </IressInline>
        </IressCol>
      </IressRow>
      <ApplyInstructionModal
        handleApplyNewInstruction={handleApplyNewInstruction}
      />
      <ApplyInstructionErrorModal />
    </IressForm>
  );
};

export default SwitchForm;
