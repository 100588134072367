import { IressButton, IressIcon, IressStack } from '@iress/components-react';

function ButtonLinkWithIcon(
  props: Readonly<{
    onClick: () => void;
  }>,
) {
  return (
    <IressStack gutter={IressStack.Gutter.Md}>
      <IressButton
        mode={IressButton.Mode.Link}
        type={IressButton.Type.Button}
        data-testid="button-delete"
        onClick={props.onClick}
      >
        <IressIcon
          name="trash"
          mode={IressIcon.Mode.Muted}
          slot={IressButton.Slot.IconOnly}
          screenreaderText="Delete"
          size={IressIcon.Size.Lg}
        />
      </IressButton>
    </IressStack>
  );
}

export default ButtonLinkWithIcon;
