import {
  InputKeyboardEvent,
  IressField,
  IressInput,
} from '@iress/components-react';
import './style.css';

interface InputPercentageProps {
  label?: string;
  dataTestId: string;
  min: string;
  max: string;
  step: string;
  valueMissing: string;
  rangeOverflow: string;
  rangeUnderflow: string;
  stepMismatch: string;
  inputValue: string;
  handleChange: (event: InputKeyboardEvent) => void;
  hiddenLabel?: boolean;
}

function InputPercentage(props: Readonly<InputPercentageProps>) {
  return (
    <IressField
      label={props.label ?? ''}
      data-testid={'label-' + props.dataTestId}
      valueMissing={props.valueMissing}
      rangeOverflow={props.rangeOverflow}
      rangeUnderflow={props.rangeUnderflow}
      stepMismatch={props.stepMismatch}
      hiddenLabel={props.hiddenLabel ?? false}
    >
      <IressInput
        type={IressInput.Type.Number}
        data-testid={'input-' + props.dataTestId}
        min={props.min}
        max={props.max}
        step={props.step}
        required
        onChange={props.handleChange}
        value={props.inputValue}
      >
        <span slot={IressInput.Slot.Append}>%</span>
      </IressInput>
    </IressField>
  );
}
export default InputPercentage;
