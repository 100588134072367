import { IressButton, IressIcon, IressStack } from '@iress/components-react';

function ButtonLinkWithIcon(
  props: Readonly<{
    onClick: () => void;
    dataTestId: string;
    iconName: string;
    linkText: string;
  }>,
) {
  return (
    <IressStack gutter={IressStack.Gutter.Md}>
      <IressButton
        mode={IressButton.Mode.Link}
        type={IressButton.Type.Button}
        data-testid={props.dataTestId}
        onClick={props.onClick}
      >
        <IressIcon name={props.iconName} slot={IressButton.Slot.Prepend} />
        {props.linkText}
      </IressButton>
    </IressStack>
  );
}

export default ButtonLinkWithIcon;
