import { createSlice } from '@reduxjs/toolkit';
import { fetchSavedProgress } from './savedProgressThunk';

interface SavedProgressState {
  data: string;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: string;
}

export const initialSavedProgressState: SavedProgressState = {
  data: '',
  loading: 'idle',
  error: '',
};

export const SavedProgressSlice = createSlice({
  name: 'SavedProgress',
  initialState: initialSavedProgressState,
  reducers: {
    resetSavedProgressState: (state) => {
      Object.assign(state, initialSavedProgressState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSavedProgress.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = 'succeeded';
      })
      .addCase(fetchSavedProgress.rejected, (state) => {
        state.loading = 'failed';
        state.error = 'Failed to fetch Saved Progress';
      })
      .addCase(fetchSavedProgress.pending, (state) => {
        state.loading = 'pending';
        state.error = '';
      });
  },
});

export const { resetSavedProgressState } = SavedProgressSlice.actions;
export default SavedProgressSlice.reducer;
