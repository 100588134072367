import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { AccountGroupsReducer } from '@app/features/AccountGroups';
import { SecuritiesReducer } from '@app/features/Securities';
import { SysAdminReducer } from '@app/features/Sysadmin';
import { saveProgressListenerMiddleware } from './middleware';
import { PageReducer } from '@app/features/Page';
import { SavedProgressReducer } from '@app/features/SavedProgress';
import { InstructionsReducer } from '@app/features/Instructions';

export const rootReducer = combineReducers({
  accountGroups: AccountGroupsReducer,
  securities: SecuritiesReducer,
  sysadmin: SysAdminReducer,
  page: PageReducer,
  savedProgress: SavedProgressReducer,
  instructions: InstructionsReducer,
});

export const setupStore = (
  preloadedState?: Partial<RootState>,
  unleashToggle?: boolean,
) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      unleashToggle
        ? getDefaultMiddleware().prepend(
            saveProgressListenerMiddleware.middleware,
          )
        : getDefaultMiddleware(),
  });
};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore['dispatch'];
