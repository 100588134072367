import {
  setProposals,
  setSelectedProposals,
  updatePositionSelection,
  updateProposal,
} from '@app/features/AccountGroups';
import { saveProgress } from '@app/services/progress';
import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { RootState } from './store';
import { modifyStateForSavingProgress } from '@app/utils/modifiers.utils';

const actionsListenedTo = isAnyOf(
  updatePositionSelection,
  setProposals,
  setSelectedProposals,
  updateProposal,
);

export const saveProgressListenerMiddleware =
  createListenerMiddleware<RootState>();

let saveTimer: ReturnType<typeof setTimeout> | undefined;
export const DEBOUNCE_TIME = 3000; // 3 seconds
export function saveProgressDebounce(state: RootState) {
  if (saveTimer) {
    clearTimeout(saveTimer);
  }
  saveTimer = setTimeout(() => {
    const newState = modifyStateForSavingProgress(state);
    void saveProgress(newState).then(() => {
      console.log('Progress saved');
    });
  }, DEBOUNCE_TIME);
}

saveProgressListenerMiddleware.startListening({
  matcher: actionsListenedTo,
  effect: (_, action) => {
    // Save progress to the server
    console.log('Saving progress to the server');
    const state = action.getState();
    saveProgressDebounce(state);
  },
});
