import { showModal } from '@iress/components-react';
import { NavigateFunction } from 'react-router-dom';

import {
  resetAccountGroupsState,
  setAccountGroupsState,
  setDataItems,
} from '@app/features/AccountGroups';
import {
  resetInstructionsState,
  setInstructionsState,
} from '@app/features/Instructions';
import { resetPageState, setPageState } from '@app/features/Page';
import { resetSavedProgressState } from '@app/features/SavedProgress';
import {
  resetSecuritiesState,
  setSecuritiesState,
} from '@app/features/Securities';
import { resetSysadminState } from '@app/features/Sysadmin';
import { DataItem } from '@app/types/accountGroups';

import { AppDispatch, RootState } from './store';

export const clickOutsideHandler = (
  event: MouseEvent,
  bsaRef: React.RefObject<HTMLDivElement>,
  modalId: string,
  setShowModal: typeof showModal,
) => {
  if (bsaRef.current && !bsaRef.current.contains(event.target as Node)) {
    // Trigger your event here
    setShowModal(modalId, true);
  }
};

export function leaveBsaHandler(time: number = 0) {
  setTimeout(() => {
    window.location.hash = 'thehub';
  }, time);
}

export const slicesSetters = {
  accountGroups: {
    setState: setAccountGroupsState,
    resetState: resetAccountGroupsState,
  },
  securities: {
    setState: setSecuritiesState,
    resetState: resetSecuritiesState,
  },
  page: {
    setState: setPageState,
    resetState: resetPageState,
  },
  instructions: {
    setState: setInstructionsState,
    setStateInOrder: setInstructionsState,
    resetState: resetInstructionsState,
  },
};
export function deleteGroupsItemsFromWindow(env: string | undefined) {
  if (env !== 'development') {
    delete window.PORTFOLIO_BULK_SECURITY_ACTIONS_DATA;
  }
}
export function loadSavedProgress(
  state: RootState,
  dispatch: AppDispatch,
  navigate: NavigateFunction,
) {
  for (const key in slicesSetters) {
    if (key in state) {
      const { setState } = slicesSetters[key as keyof typeof slicesSetters];
      dispatch(setState(state[key as keyof RootState] as any));
    }
  }
  navigate(state.page.path);
}

export function startNewSession(data: DataItem[], dispatch: AppDispatch) {
  dispatch(setDataItems(data));
}

export function resetState(dispatch: AppDispatch) {
  for (const key in slicesSetters) {
    const { resetState } = slicesSetters[key as keyof typeof slicesSetters];
    dispatch(resetState());
  }
  dispatch(resetSysadminState());
  dispatch(resetSavedProgressState());
}

export function checkDataHandler(
  saveProgressData: RootState,
  dispatch: AppDispatch,
  navigate: NavigateFunction,
  continueSessionModalId: string,
  setShowModal: typeof showModal,
) {
  const hasData =
    window.PORTFOLIO_BULK_SECURITY_ACTIONS_DATA &&
    window.PORTFOLIO_BULK_SECURITY_ACTIONS_DATA.data.length > 0;
  const hasSavedProgress = Boolean(saveProgressData);
  const PORTFOLIO_BULK_SECURITY_ACTIONS_DATA =
    window.PORTFOLIO_BULK_SECURITY_ACTIONS_DATA?.data;

  if (hasData) {
    if (hasSavedProgress) {
      setShowModal(continueSessionModalId, true);
    } else if (PORTFOLIO_BULK_SECURITY_ACTIONS_DATA) {
      startNewSession(PORTFOLIO_BULK_SECURITY_ACTIONS_DATA, dispatch);
    }
  } else if (hasSavedProgress) {
    loadSavedProgress(saveProgressData, dispatch, navigate);
  }
}
