import { SharedSecurityDetailsResponse } from '@bsa/shared-types';
/* 
Tip:  When you update the securities service mocks in the bff
      just copy the expected values from bff src/__mocks__/securities.mocks.ts file
      They should have the exact value and structure
*/
export const mockSecuritiesDetailsResponse: SharedSecurityDetailsResponse[] = [
  {
    securityId: 1241544973,
    code: '0IPI',
    exchange: 'LSE',
    description: 'VINZAVOD ASENOV - VINZAVOD ASENOVG ORD SHS',
    sedol: '',
    isin: 'BG11VIASAT18',
    nativeCurrency: 'BGN',
    nativePrice: '0.310',
    userCurrency: '',
    marketPrice: '',
    assetClass: 'EMERGING MARKETS EQUITY',
    searchType: 'CODE/DESCRIPTION',
    allowPartialVolume: true,
  },
  {
    securityId: 1241545023,
    code: '0IU5',
    exchange: 'LSE',
    description: 'ZAHARNI ZAVODI - ZAHARNI ZAVODI A ORD SHS',
    sedol: '',
    isin: 'BG11ZAGOBT19',
    nativeCurrency: 'BGN',
    nativePrice: '4.820',
    userCurrency: '',
    marketPrice: '',
    assetClass: 'EMERGING MARKETS EQUITY',
    searchType: 'CODE/DESCRIPTION',
    allowPartialVolume: true,
  },
  {
    securityId: 1241569776,
    code: '0LQQ',
    exchange: 'LSE',
    description: 'VODAFONE GROUP - VODAFONE GROUP ADR REPRESENTING 10 ORD S',
    sedol: '',
    isin: 'US92857W3088',
    nativeCurrency: 'USD',
    nativePrice: '26.540',
    userCurrency: '',
    marketPrice: '',
    assetClass: 'US EQUITY',
    searchType: 'CODE/DESCRIPTION',
    allowPartialVolume: true,
  },
  {
    securityId: 925447778,
    code: '0P00006VOD',
    exchange: 'USF',
    description: 'American Century U.S. Mid Cap Value',
    sedol: '',
    isin: '',
    nativeCurrency: 'USD',
    nativePrice: '0.0',
    userCurrency: '',
    marketPrice: '',
    assetClass: 'GLOBAL BONDS (EX UK)',
    searchType: 'CODE/DESCRIPTION',
    allowPartialVolume: true,
  },
  {
    securityId: 924852750,
    code: '0P00007VOD',
    exchange: 'IRF',
    description: 'Argo Pan European Div Inc A EUR Inc',
    sedol: 'B1NGW94',
    isin: 'IE00B0S5Q754',
    nativeCurrency: 'EUR',
    nativePrice: '0.0',
    userCurrency: '',
    marketPrice: '',
    assetClass: 'GLOBAL BONDS (EX UK)',
    searchType: 'CODE/DESCRIPTION',
    allowPartialVolume: true,
  },
  {
    securityId: 924448435,
    code: '0P0000RVOD',
    exchange: 'GBF',
    description: 'Elite Webb Cap Smaller Co Inc & Gr Inc',
    sedol: 'B6253G1',
    isin: 'GB00B6253G17',
    nativeCurrency: 'GBP',
    nativePrice: '0.0',
    userCurrency: '',
    marketPrice: '',
    assetClass: 'US EQUITY',
    searchType: 'CODE/DESCRIPTION',
    allowPartialVolume: true,
  },
  {
    securityId: 1241562707,
    code: '15NU',
    exchange: 'LSE',
    description: 'VODAFONE 26 - 2.200% NTS 25/08/26',
    sedol: 'BD879Z1',
    isin: 'XS1372839214',
    nativeCurrency: 'EUR',
    nativePrice: '1.0739979',
    userCurrency: '',
    marketPrice: '',
    assetClass: 'GLOBAL BONDS (EX UK)',
    searchType: 'CODE/DESCRIPTION',
    allowPartialVolume: true,
  },
  {
    securityId: 2146502999,
    code: '1VODH',
    exchange: 'PAR',
    description: 'VEOLI20.64TCIOPENH - VEOLI20.64TCIOPENH',
    sedol: '',
    isin: 'NLGS00016JT3',
    nativeCurrency: 'EUR',
    nativePrice: '7.10',
    userCurrency: '',
    marketPrice: '',
    assetClass: 'EUROPEAN EQUITY',
    searchType: 'CODE/DESCRIPTION',
    allowPartialVolume: true,
  },
  {
    securityId: 1241579414,
    code: '3VOD',
    exchange: 'LSE',
    description: '3X VODAFONE - LEVSHARES 3X VODAFONE ETP',
    sedol: '',
    isin: 'XS2297637253',
    nativeCurrency: 'GBP',
    nativePrice: '0.6205',
    userCurrency: '',
    marketPrice: '',
    assetClass: 'UK EQUITY',
    searchType: 'CODE/DESCRIPTION',
    allowPartialVolume: true,
  },
  {
    securityId: 1241563854,
    code: '43WO',
    exchange: 'LSE',
    description: 'VODAFONE 31 - 1.600% NTS 29/07/31',
    sedol: 'BD89XF5',
    isin: 'XS1463101680',
    nativeCurrency: 'EUR',
    nativePrice: '0.92599',
    userCurrency: '',
    marketPrice: '',
    assetClass: 'GLOBAL BONDS (EX UK)',
    searchType: 'CODE/DESCRIPTION',
    allowPartialVolume: true,
  },
  {
    securityId: 1241563880,
    code: '43ZV',
    exchange: 'LSE',
    description: 'VODAFONE 49 - 3.375% NTS 08/08/49',
    sedol: 'BYYB857',
    isin: 'XS1468494239',
    nativeCurrency: 'GBP',
    nativePrice: '0.95085',
    userCurrency: '',
    marketPrice: '',
    assetClass: 'UK FIXED INCOME',
    searchType: 'CODE/DESCRIPTION',
    allowPartialVolume: true,
  },
  {
    securityId: 1241563886,
    code: '44AN',
    exchange: 'LSE',
    description: 'VODAFONE 46 - 4.600% NTS 09/08/46',
    sedol: 'BDBBPC9',
    isin: 'XS1463155348',
    nativeCurrency: 'USD',
    nativePrice: '0.00',
    userCurrency: '',
    marketPrice: '',
    assetClass: 'GLOBAL BONDS (EX UK)',
    searchType: 'CODE/DESCRIPTION',
    allowPartialVolume: true,
  },
];

export const mockSecurityMarketPriceResponse: Partial<SharedSecurityDetailsResponse> =
  {
    securityId: 1241544973,
    userCurrency: 'GBP',
    marketPrice: '249.92723750',
  };
