import {
  IressCol,
  IressForm,
  IressRow,
  IressInline,
  ButtonMode,
} from '@iress/components-react';
import { RefObject } from 'react';
import {
  SelectInstruction,
  SelectAllocationType,
  SelectExcludeServiceType,
} from '../Select';
import { InputUnitPrice, InputPercentage } from '../Input';
import { ButtonSubmit } from '../Button';
import { AgGridReact } from 'ag-grid-react';
import { useInstructionSubmission } from './hooks/useInstructionSubmission';
import { useInstructionState } from './hooks/useInstructionState';
import ApplyInstructionModal from './ApplyInstructionModal';
import ApplyInstructionErrorModal from './ApplyInstructionErrorModal';

interface Props {
  gridRef: RefObject<AgGridReact>;
}

const InstructionsForm = ({ gridRef }: Readonly<Props>) => {
  const instructionState = useInstructionState();
  const {
    uiState: {
      selectedLevel,
      showUnitPrice,
      showPercentage,
      showAllocationType,
      showExcludeServiceType,
    },
    handlers: {
      handleInstructionChange,
      handlePercentageChange,
      handleAllocationTypeChange,
      handleExcludeServiceTypeChange,
    },
  } = instructionState;

  const {
    handleFormOnSubmitEvent,
    handleApplyNewInstruction,
    isLoaderVisible,
  } = useInstructionSubmission({
    gridRef,
    instructionState,
  });

  return (
    <IressForm
      data-testid="adjustments-form"
      disabledClearOnSubmit
      hiddenErrorSummary
      onSubmit={handleFormOnSubmitEvent}
    >
      <IressRow
        gutter={IressRow.Gutter.Lg}
        verticalAlign={IressRow.VerticalAlign.Bottom}
      >
        <IressCol span={IressCol.Span.Three}>
          <SelectInstruction
            handleChange={handleInstructionChange}
            selectedLevel={selectedLevel}
          />
        </IressCol>
        <IressCol>
          <IressInline
            gutter={IressInline.Gutter.Lg}
            verticalAlign={IressInline.VerticalAlign.Bottom}
          >
            {showPercentage && (
              <InputPercentage handleChange={handlePercentageChange} />
            )}
            {showUnitPrice && <InputUnitPrice />}

            {showAllocationType && (
              <SelectAllocationType handleChange={handleAllocationTypeChange} />
            )}

            {showExcludeServiceType && (
              <SelectExcludeServiceType
                onSelect={handleExcludeServiceTypeChange}
              />
            )}

            <ButtonSubmit
              key="apply"
              buttonText="Apply"
              dataTestId="submit-btn"
              mode={ButtonMode.Primary}
              isLoaderVisible={isLoaderVisible}
            />
          </IressInline>
        </IressCol>
      </IressRow>
      <ApplyInstructionModal
        handleApplyNewInstruction={handleApplyNewInstruction}
      />
      <ApplyInstructionErrorModal />
    </IressForm>
  );
};

export default InstructionsForm;
