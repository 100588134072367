import { RootState } from '@app/app/store';
import { initialSavedProgressState } from '@app/features/SavedProgress';
import { initialSysadminState } from '@app/features/Sysadmin';
import {
  AccountGroupLevelResponse,
  AccountLevelResponse,
  Adjustment,
} from '@bsa/shared-types';

export function getSelectedRowIds(
  items: (Adjustment | AccountLevelResponse | AccountGroupLevelResponse)[],
) {
  return items.map((item) =>
    'accountId' in item && item.accountId
      ? item.accountId.toString()
      : item.accountGroupCode,
  );
}

export function getSelectedRowIdsSet(items: Array<string>) {
  return new Set(items);
}

export function modifyStateForSavingProgress(state: RootState) {
  return {
    ...state,
    savedProgress: initialSavedProgressState,
    sysadmin: initialSysadminState,
  };
}
