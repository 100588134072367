import {
  FormFieldStatus,
  InputKeyboardEvent,
  IressCol,
  IressField,
  IressInline,
  MenuItemMeta,
} from '@iress/components-react';
import { ComboBox } from '../ComboBox';
import './SwitchingToSecurity.css';
import { InputPercentage } from '../Input';

function SwitchingToSecurity(
  props: Readonly<{
    id: string;
    cbHandleInputChange: (query: string) => Promise<MenuItemMeta[]>;
    cbDataTestId?: string;
    cbName?: string;
    cbNoResultsText?: string;
    cbSelectedOption?: MenuItemMeta;
    cbOnchange?: (e: FormFieldStatus<HTMLElement>) => void;
    inputLabel?: string;
    inputKey: string;
    inputDataTestId: string;
    inputMin: string;
    inputMax: string;
    inputStep: string;
    inputValueMissing: string;
    inputOnChange: (event: InputKeyboardEvent) => void;
    inputValue?: string;
    inputErrorText: string;
    hiddenLabel: boolean;
  }>,
) {
  return (
    <IressInline
      verticalAlign={IressInline.VerticalAlign.Bottom}
      horizontalAlign={IressInline.HorizontalAlign.Left}
      id={props.id}
      data-testid={props.id}
      noWrap
    >
      <IressCol span={IressCol.Span.Nine}>
        <IressField
          label="Switching to"
          data-testid="switching-security-label"
          hiddenLabel={props.hiddenLabel}
        >
          <ComboBox
            handleInputChange={props.cbHandleInputChange}
            noResultsText={props.cbNoResultsText}
            selectedOption={props.cbSelectedOption}
            dataTestId={props.cbDataTestId}
            name={props.cbName}
            className="switching-to-security-combobox"
            placeholder="Search for a security"
            onChange={props.cbOnchange}
          />
        </IressField>
      </IressCol>
      <IressCol span={IressCol.Span.Three}>
        <InputPercentage
          label={props.inputLabel}
          key={props.inputKey}
          dataTestId={props.inputDataTestId}
          min={props.inputMin}
          max={props.inputMax}
          step={props.inputStep}
          valueMissing={props.inputValueMissing}
          rangeOverflow={props.inputErrorText}
          rangeUnderflow={props.inputErrorText}
          stepMismatch={props.inputErrorText}
          handleChange={props.inputOnChange}
          inputValue={props.inputValue ?? '0'}
          hiddenLabel={props.hiddenLabel}
        />
      </IressCol>
    </IressInline>
  );
}
export default SwitchingToSecurity;
