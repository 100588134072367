import { UNLEASH_TOGGLES } from '@app/app/unleash';
import { useFlag } from '@unleash/proxy-client-react';
import { InstructionsForm } from '@app/components/InstructionsForm';
import {
  IressButton,
  IressCol,
  IressPanel,
  IressRow,
  IressStack,
  showModal,
} from '@iress/components-react';
import { InstructionsPageTitle } from '@app/components/Titles';
import { ProposalsGrid } from '@app/components/ProposalsGrid';
import { ButtonPrimary } from '@app/components/Button';
import { Modal } from '@app/components/Modal';
import { AgGridReact } from 'ag-grid-react';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { CtaPanel } from '@app/components/CtaPanel';
import { useAppDispatch, useAppSelector } from '@app/app/hooks';
import { AppDispatch, RootState } from '@app/app/store';
import {
  SharedAccountAdjustmentDetails,
  SharedSecurityDetailsResponse,
} from '@bsa/shared-types';
import {
  createAdjustmentOnProposal,
  setAdjustmentProposalResponseStatus,
} from '@app/features/AccountGroups';
import ButtonExit from '@app/components/Button/ButtonExit';
import ButtonPreviousStep from '@app/components/Button/ButtonPreviousStep';
import { SAVE_SESSION_MODAL_ID, RETURN_MODAL_ID } from '@app/app/App';
import { handleGenerateProposalStatus } from './InstructionsPage.utils';
import { resetInstructionsState } from '@app/features/Instructions';

interface AdjustmentData {
  accountId: number;
  proposedUnits: string;
}

const generateProposalErrorModalId = 'generate-proposal-error-modal-ok';

export const useInstructionsPage = (
  security: SharedSecurityDetailsResponse,
  dispatch: AppDispatch,
  gridRef: RefObject<AgGridReact>,
  setShowValidationErrorMessage: (value: boolean) => void,
  setValidationErrorMessage: (value: string) => void,
  setIsLoading: (value: boolean) => void,
) => {
  const handleOnClick = useCallback(() => {
    let isFilteredNodeSelected = false;
    gridRef.current?.api.forEachNodeAfterFilter((node) => {
      if (node.isSelected()) {
        isFilteredNodeSelected = true;
      }
    });
    if (!isFilteredNodeSelected) {
      setValidationErrorMessage(
        `Please select at least one account before generating proposals`,
      );
      setShowValidationErrorMessage(true);
    } else if (gridRef.current) {
      const updatedProposals: SharedAccountAdjustmentDetails[] = [];
      gridRef.current.api.forEachNodeAfterFilter((node) => {
        const adjustmentData = node.data as AdjustmentData;
        if (node.isSelected() && Number(adjustmentData.proposedUnits) !== 0) {
          updatedProposals.push({
            accountId: adjustmentData.accountId,
            adjustmentType: 0,
            adjustmentValue: Number(adjustmentData.proposedUnits),
          });
        }
      });
      if (updatedProposals.length > 0) {
        setIsLoading(true);
        void dispatch(
          createAdjustmentOnProposal({
            selectedSecurityId: Number(security.securityId),
            adjustments: updatedProposals,
          }),
        );
      } else {
        setValidationErrorMessage(
          `Please apply an instruction before generating proposals`,
        );
        setShowValidationErrorMessage(true);
      }
    }
  }, [
    gridRef,
    setValidationErrorMessage,
    setShowValidationErrorMessage,
    dispatch,
    security.securityId,
    setIsLoading,
  ]);

  const handleOnExit = useCallback(() => {}, []);
  return {
    handleOnClick,
    handleOnExit,
    setShowValidationErrorMessage,
    setIsLoading,
  };
};

function InstructionsPage() {
  const dispatch = useAppDispatch();
  const security = useAppSelector(
    (state: RootState) => state.securities.selectedSecurity,
  );
  const [validationErrorMessage, setValidationErrorMessage] =
    useState<string>('');
  const adjustmentProposalResponses = useAppSelector(
    (state: RootState) => state.accountGroups.adjustmentProposalResponses,
  );
  const gridRef = useRef<AgGridReact>(null);
  const totalRowCount: number = 0;
  const [proposalsCount, setProposalsCount] = useState(totalRowCount);
  const [totalRowsCount, setTotalRowsCount] = useState(totalRowCount);
  const [showValidationErrorMessage, setShowValidationErrorMessage] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isSaveProgressEnabled = useFlag(UNLEASH_TOGGLES.SaveProgress);

  const okModalButtons = [
    <ButtonPrimary key="OK" buttonText="OK" link="/" dataTestid="btn-ok" />,
  ];

  const { handleOnClick } = useInstructionsPage(
    security,
    dispatch,
    gridRef,
    setShowValidationErrorMessage,
    setValidationErrorMessage,
    setIsLoading,
  );

  useEffect(() => {
    setShowValidationErrorMessage(false);
  }, [proposalsCount, setShowValidationErrorMessage]);

  useEffect(() => {
    handleGenerateProposalStatus(
      adjustmentProposalResponses.loading,
      setIsLoading,
      isSaveProgressEnabled,
      generateProposalErrorModalId,
      dispatch,
    );
    return () => {
      dispatch(setAdjustmentProposalResponseStatus('idle'));
    };
  }, [adjustmentProposalResponses.loading, dispatch, isSaveProgressEnabled]);

  useEffect(() => {
    return () => {
      dispatch(resetInstructionsState());
    };
  }, [dispatch]);

  return (
    <div
      style={{
        height: '100%',
        flexDirection: 'column',
        display: 'flex',
        gap: '8px',
      }}
    >
      <IressRow>
        <IressCol>
          <InstructionsPageTitle />
        </IressCol>
        <IressCol>
          <CtaPanel
            selectedRowCount={proposalsCount.toString()}
            totalRowCount={totalRowsCount.toString()}
            selectedLevel="account"
            showErrorMessage={showValidationErrorMessage}
            errorMessage={validationErrorMessage}
          >
            {isSaveProgressEnabled && (
              <ButtonExit
                onClick={() => showModal(SAVE_SESSION_MODAL_ID, true)}
              />
            )}
            <ButtonPreviousStep
              onClick={() => showModal(RETURN_MODAL_ID, true)}
            />
            <IressButton
              key="generateProposals"
              mode={IressButton.Mode.Primary}
              type={IressButton.Type.Button}
              data-testid="primary-btn"
              onClick={handleOnClick}
              loading={isLoading}
            >
              Generate Proposals
            </IressButton>
          </CtaPanel>
        </IressCol>
      </IressRow>
      <IressPanel
        padding={IressPanel.Padding.Sm}
        background={IressPanel.Background.Default}
      >
        <IressStack gutter={IressStack.Gutter.Md}>
          <InstructionsForm gridRef={gridRef} />
        </IressStack>
      </IressPanel>
      <Modal
        id={generateProposalErrorModalId}
        dataTestId={generateProposalErrorModalId}
        title="Something went wrong"
        message="Your progress has been saved. Please try again later."
        buttons={okModalButtons}
      />
      <ProposalsGrid
        gridRef={gridRef}
        setProposalsCount={setProposalsCount}
        setTotalRowsCount={setTotalRowsCount}
        setShowValidationErrorMessage={setShowValidationErrorMessage}
      />
    </div>
  );
}
export default InstructionsPage;
