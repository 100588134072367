import { useCallback, useEffect, useRef } from 'react';
import { fetchFormattingSettings } from '@app/features/Sysadmin';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  HeadingLevel,
  IressButton,
  IressPanel,
  showModal,
  toast,
} from '@iress/components-react';
import { useAppDispatch, useAppSelector } from './hooks';
import { NoActionsPage } from '@app/pages';
import { useFlag } from '@unleash/proxy-client-react';
import { UNLEASH_TOGGLES } from './unleash';
import { Modal } from '@app/components/Modal';
import {
  checkDataHandler,
  clickOutsideHandler,
  deleteGroupsItemsFromWindow,
  leaveBsaHandler,
  loadSavedProgress,
  resetState,
  startNewSession,
} from './App.handlers';
import { setCurrentPage } from '@app/features/Page';
import {
  fetchSavedProgress,
  saveProgressThunk,
} from '@app/features/SavedProgress/savedProgressThunk';
import { RootState } from './store';
import { deleteSavedSession } from '@app/services/progress';

export const SAVE_SESSION_MODAL_ID = 'save-session-modal';
export const CONTINUE_SESSION_MODAL_ID = 'modal-continue-session';

export function useApp(
  bsaRef: React.RefObject<HTMLDivElement>,
  isSaveProgressEnabled: boolean,
  exitModalId: string,
  continueSessionModalId: string,
  setShowModal: typeof showModal,
) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const saveProgressData = useAppSelector((state) => state.savedProgress.data);
  const saveProgressLoadingStatus = useAppSelector(
    (state) => state.savedProgress.loading,
  );
  const groups = useAppSelector((state) => state.accountGroups.dataItems);
  const PORTFOLIO_BULK_SECURITY_ACTIONS_DATA =
    window.PORTFOLIO_BULK_SECURITY_ACTIONS_DATA?.data;

  const checkData = useCallback(() => {
    if (saveProgressLoadingStatus === 'pending') return;
    checkDataHandler(
      saveProgressData as unknown as RootState,
      dispatch,
      navigate,
      continueSessionModalId,
      setShowModal,
    );
  }, [
    continueSessionModalId,
    dispatch,
    navigate,
    saveProgressData,
    saveProgressLoadingStatus,
    setShowModal,
  ]);

  const handleClickOutside = useCallback(
    (event: MouseEvent) =>
      clickOutsideHandler(event, bsaRef, exitModalId, setShowModal),
    [bsaRef, exitModalId, setShowModal],
  );

  const showNoActionsPage = useCallback(
    () => groups.length <= 0 && isSaveProgressEnabled,
    [groups, isSaveProgressEnabled],
  );
  const handleExitModalSaveClick = useCallback(() => {
    void dispatch(saveProgressThunk()).then(() => {
      toast.success({
        headingText: 'Session saved',
        headingLevel: HeadingLevel.H3,
      });
      leaveBsaHandler(2000);
    });
    setShowModal(exitModalId, false);
  }, [setShowModal, exitModalId, dispatch]);

  const handleExitModalDeleteClick = useCallback(() => {
    void deleteSavedSession().then(() => {
      toast.success({
        headingText: 'Session deleted',
        headingLevel: HeadingLevel.H3,
      });
      leaveBsaHandler(2000);
    });
    setShowModal(exitModalId, false);
  }, [setShowModal, exitModalId]);

  const handleSessionModalContinueClick = useCallback(() => {
    loadSavedProgress(
      saveProgressData as unknown as RootState,
      dispatch,
      navigate,
    );
    setShowModal(continueSessionModalId, false);
  }, [
    continueSessionModalId,
    dispatch,
    navigate,
    saveProgressData,
    setShowModal,
  ]);

  const handleSessionModalDeleteClick = useCallback(() => {
    if (PORTFOLIO_BULK_SECURITY_ACTIONS_DATA)
      startNewSession(PORTFOLIO_BULK_SECURITY_ACTIONS_DATA, dispatch);
    setShowModal(continueSessionModalId, false);
    void deleteSavedSession();
    toast.success({
      headingText: 'Previous session deleted',
      headingLevel: HeadingLevel.H3,
    });
  }, [
    PORTFOLIO_BULK_SECURITY_ACTIONS_DATA,
    continueSessionModalId,
    dispatch,
    setShowModal,
  ]);

  return {
    checkData,
    handleClickOutside,
    showNoActionsPage,
    handleExitModalSaveClick,
    handleExitModalDeleteClick,
    handleSessionModalContinueClick,
    handleSessionModalDeleteClick,
  };
}

function App() {
  const bsaRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const isSaveProgressEnabled = useFlag(UNLEASH_TOGGLES.SaveProgress);
  const isSwitchEnabled = useFlag(UNLEASH_TOGGLES.Switch);
  console.log('Switch toggle is:', isSwitchEnabled); // This line is to be removed once the toggle is in use
  const location = useLocation();
  const {
    checkData,
    showNoActionsPage,
    handleClickOutside,
    handleExitModalSaveClick,
    handleExitModalDeleteClick,
    handleSessionModalContinueClick,
    handleSessionModalDeleteClick,
  } = useApp(
    bsaRef,
    isSaveProgressEnabled,
    SAVE_SESSION_MODAL_ID,
    CONTINUE_SESSION_MODAL_ID,
    showModal,
  );

  useEffect(() => {
    if (isSaveProgressEnabled) checkData();
    else if (window.PORTFOLIO_BULK_SECURITY_ACTIONS_DATA?.data)
      startNewSession(
        window.PORTFOLIO_BULK_SECURITY_ACTIONS_DATA?.data,
        dispatch,
      );
  }, [checkData, dispatch, isSaveProgressEnabled]);

  useEffect(() => {
    if (isSaveProgressEnabled) void dispatch(fetchSavedProgress());
  }, [dispatch, isSaveProgressEnabled]);

  useEffect(() => {
    void dispatch(fetchFormattingSettings());
  }, [dispatch]);

  useEffect(() => {
    if (isSaveProgressEnabled)
      document.addEventListener('mousedown', handleClickOutside);

    return () => {
      if (isSaveProgressEnabled)
        document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, isSaveProgressEnabled]);

  useEffect(() => {
    dispatch(setCurrentPage(location.pathname));
  }, [dispatch, location]);

  useEffect(() => {
    return () => {
      resetState(dispatch);
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      deleteGroupsItemsFromWindow(process.env.NODE_ENV);
    };
  }, []);

  const exitModalButtons = [
    <IressButton
      key="Cancel"
      data-testid="modal-btn-delete-exit"
      onClick={handleExitModalDeleteClick}
      mode={IressButton.Mode.Secondary}
      type={IressButton.Type.Button}
    >
      Delete & exit
    </IressButton>,
    <IressButton
      key="save"
      data-testid="modal-btn-save-exit"
      onClick={handleExitModalSaveClick}
      mode={IressButton.Mode.Primary}
      type={IressButton.Type.Button}
    >
      Save & exit
    </IressButton>,
  ];

  const continueSessionModalButtons = [
    <IressButton
      key="Delete"
      data-testid="modal-btn-delete"
      onClick={handleSessionModalDeleteClick}
      mode={IressButton.Mode.Secondary}
      type={IressButton.Type.Button}
    >
      Delete
    </IressButton>,
    <IressButton
      key="Continue"
      data-testid="modal-btn-continue"
      onClick={handleSessionModalContinueClick}
      mode={IressButton.Mode.Primary}
      type={IressButton.Type.Button}
    >
      Continue
    </IressButton>,
  ];
  return (
    <IressPanel
      padding={IressPanel.Padding.Md}
      background={IressPanel.Background.Page}
      data-testid="app"
    >
      {showNoActionsPage() ? (
        <NoActionsPage />
      ) : (
        <div ref={bsaRef} style={{ height: 'calc(100vh - 150px)' }}>
          {isSaveProgressEnabled && (
            <>
              <Modal
                id={SAVE_SESSION_MODAL_ID}
                dataTestId={SAVE_SESSION_MODAL_ID}
                title="Save this Session?"
                message="If you save, this session will be available the next time you launch Bulk Security Actions (BSA)."
                buttons={exitModalButtons}
              />
              <Modal
                id={CONTINUE_SESSION_MODAL_ID}
                dataTestId={CONTINUE_SESSION_MODAL_ID}
                title="Continue with saved session?"
                message="You can continue or delete it to start a new session. Deleted session cannot be restored."
                buttons={continueSessionModalButtons}
              />
            </>
          )}

          <Outlet />
        </div>
      )}
    </IressPanel>
  );
}

export default App;
