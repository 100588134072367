import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { DataItem } from '@app/types/accountGroups';
import { searchAccountsHoldingSecurityService } from '@app/services/accounts';
import type {
  SharedAccountsResponse,
  AccountLevelResponse,
  AccountGroupLevelResponse,
  Adjustment,
  SharedAccountAdjustmentDetails,
  SharedProposalResponse,
  AccountGroupAdjustment,
} from '@bsa/shared-types';
import { SharedViewLevelParamExtended } from '@app/types/sharedViewLevelParamExtended';
import { createAccountAdjustmentOnProposal } from '@app/services/proposals';
import { updateProposalHelper } from './accountGroupSlice.helpers';
import { getSelectedRowIds } from '@app/utils/modifiers.utils';

export type LoadingStatus = 'idle' | 'pending' | 'succeeded' | 'failed';
type Positions = AccountGroupLevelResponse | AccountLevelResponse;
export interface AccountGroupsState {
  dataItems: DataItem[];
  accountGroupItems: {
    data: SharedAccountsResponse;
    loading: LoadingStatus;
    error: string;
    processedCount: number;
  };
  viewData: 'withoutZeroHoldings' | 'withZeroHoldings';
  selectedPositions: string[];
  includeZeroHoldings: boolean;
  viewLevel: SharedViewLevelParamExtended;
  originalProposals: (Adjustment | AccountGroupAdjustment)[]; // This used to keep tack of the original proposals to apply instructions
  proposals: Adjustment[]; // This used to keep tack of the proposals selection
  selectedProposals: string[]; // This used to keep tack of the proposals selection
  adjustmentProposalResponses: {
    data: SharedProposalResponse;
    loading: LoadingStatus;
    error: string;
  };
}

export const initialAccountGroupState: AccountGroupsState = {
  dataItems: [],
  accountGroupItems: {
    data: { withoutZeroHoldings: [], withZeroHoldings: [] },
    loading: 'idle',
    error: '',
    processedCount: 0,
  },
  viewData: 'withoutZeroHoldings',
  selectedPositions: [],
  includeZeroHoldings: false,
  viewLevel: '',
  originalProposals: [],
  proposals: [],
  selectedProposals: [],
  adjustmentProposalResponses: {
    data: { proposals: [], failedProposals: [] },
    loading: 'idle',
    error: '',
  },
};

export const fetchAccountsHoldingSecurity = createAsyncThunk(
  'accountGroups/fetchAccountsHoldingSecurity',
  async ({
    selectedSecurityId,
    accountGroupCodes,
    viewLevel,
    updateProcessedCount,
  }: {
    selectedSecurityId: number;
    accountGroupCodes: string[];
    viewLevel: SharedViewLevelParamExtended;
    updateProcessedCount: (count: number) => void;
  }) => {
    return searchAccountsHoldingSecurityService(
      selectedSecurityId,
      accountGroupCodes,
      viewLevel,
      updateProcessedCount,
    );
  },
);

export const createAdjustmentOnProposal = createAsyncThunk(
  'accountGroups/createAccountAdjustmentOnProposal',
  async ({
    selectedSecurityId,
    adjustments,
  }: {
    selectedSecurityId: number;
    adjustments: SharedAccountAdjustmentDetails[];
  }) => {
    return createAccountAdjustmentOnProposal(selectedSecurityId, adjustments);
  },
);

export const AccountGroupsSlice = createSlice({
  name: 'AccountGroups',
  initialState: initialAccountGroupState,
  reducers: {
    setDataItems: (state, action: PayloadAction<DataItem[]>) => {
      state.dataItems = action.payload;
    },
    setViewData: (
      state,
      action: PayloadAction<AccountGroupsState['viewData']>,
    ) => {
      state.viewData = action.payload;
    },
    setSelectedPositions: (state, action: PayloadAction<Positions[]>) => {
      state.selectedPositions = getSelectedRowIds(action.payload);
    },

    setIncludeZeroHoldings: (
      state,
      action: PayloadAction<AccountGroupsState['includeZeroHoldings']>,
    ) => {
      state.includeZeroHoldings = action.payload;
    },
    setViewLevel: (
      state,
      action: PayloadAction<AccountGroupsState['viewLevel']>,
    ) => {
      state.viewLevel = action.payload;
    },
    setProposals: (
      state,
      action: PayloadAction<AccountGroupsState['proposals']>,
    ) => {
      state.proposals = action.payload;
    },
    updateProposal: (
      state,
      action: PayloadAction<AccountGroupsState['proposals']>,
    ) => {
      state.proposals = updateProposalHelper(state, action);
    },
    setSelectedProposals: (
      state,
      action: PayloadAction<AccountGroupsState['proposals']>,
    ) => {
      state.selectedProposals = getSelectedRowIds(action.payload);
    },
    setOriginalProposals: (
      state,
      action: PayloadAction<AccountGroupsState['originalProposals']>,
    ) => {
      state.originalProposals = action.payload;
    },
    setAdjustmentProposalResponseStatus: (
      state,
      action: PayloadAction<LoadingStatus>,
    ) => {
      state.adjustmentProposalResponses.loading = action.payload;
    },
    updatePositionSelection: (
      state,
      action: PayloadAction<SharedAccountsResponse>,
    ) => {
      state.accountGroupItems.data = action.payload;
    },
    setAccountGroupsState: (
      state,
      action: PayloadAction<AccountGroupsState>,
    ) => {
      Object.assign(state, action.payload);
    },
    resetAccountGroupsState: (state) => {
      Object.assign(state, initialAccountGroupState);
    },
    updateProcessedCount: (state, action: PayloadAction<number>) => {
      state.accountGroupItems.processedCount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccountsHoldingSecurity.fulfilled, (state, action) => {
        state.accountGroupItems.data = action.payload;
        state.accountGroupItems.loading = 'succeeded';
      })
      .addCase(fetchAccountsHoldingSecurity.pending, (state) => {
        state.accountGroupItems.data = {
          withoutZeroHoldings: [],
          withZeroHoldings: [],
        };
        state.accountGroupItems.loading = 'pending';
        state.accountGroupItems.processedCount = 0;
      })
      .addCase(fetchAccountsHoldingSecurity.rejected, (state, action) => {
        state.accountGroupItems.data = {
          withoutZeroHoldings: [],
          withZeroHoldings: [],
        };
        state.accountGroupItems.loading = 'failed';
        state.accountGroupItems.error = action.error.message as string;
        state.accountGroupItems.processedCount = 0;
      })
      .addCase(createAdjustmentOnProposal.fulfilled, (state, action) => {
        state.adjustmentProposalResponses.data = action.payload;
        state.adjustmentProposalResponses.loading = 'succeeded';
      })
      .addCase(createAdjustmentOnProposal.pending, (state) => {
        state.adjustmentProposalResponses.data = {
          proposals: [],
          failedProposals: [],
        };
        state.adjustmentProposalResponses.loading = 'pending';
      })
      .addCase(createAdjustmentOnProposal.rejected, (state, action) => {
        state.adjustmentProposalResponses.data = {
          proposals: [],
          failedProposals: [],
        };
        state.adjustmentProposalResponses.loading = 'failed';
        state.adjustmentProposalResponses.error = action.error
          .message as string;
      });
  },
});

export const {
  setDataItems,
  setViewData,
  setSelectedPositions,
  updatePositionSelection,
  setProposals,
  updateProposal,
  setSelectedProposals,
  setOriginalProposals,
  setIncludeZeroHoldings,
  setViewLevel,
  setAdjustmentProposalResponseStatus,
  setAccountGroupsState,
  resetAccountGroupsState,
  updateProcessedCount,
} = AccountGroupsSlice.actions;

export default AccountGroupsSlice.reducer;
