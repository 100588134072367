import { RootState } from '@app/app/store';
import { getProgress, saveProgress } from '@app/services/progress';
import { modifyStateForSavingProgress } from '@app/utils/modifiers.utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchSavedProgress = createAsyncThunk(
  'SavedProgress/fetchSavedProgress',
  async () => {
    return getProgress();
  },
);
export const saveProgressThunk = createAsyncThunk(
  'SavedProgress/saveProgress',
  async (_, { getState }) => {
    const state = modifyStateForSavingProgress(getState() as RootState);
    return saveProgress(state);
  },
);
