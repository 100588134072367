import {
  createDecreaseToInstructionForAccountGroups,
  createDecreaseToInstruction,
  createIncreaseToInstructionForAccountGroups,
  createIncreaseToInstruction,
} from '@app/services/instructions';
import {
  Adjustment,
  AccountGroupAdjustment,
  SharedInstructionAdjustment,
  InstructionAdjustmentType,
  AllocationType,
  SharedInstructionAccountGroupAdjustment,
} from '@bsa/shared-types';
import { useCallback } from 'react';
import { ViewLevels } from '../constants';

export const useInstructionHandlers = (viewLevel: string) => {
  const handleSellAllInstruction = useCallback(
    async (proposals: (Adjustment | AccountGroupAdjustment)[]) => {
      const adjustment: SharedInstructionAdjustment = {
        type: InstructionAdjustmentType.DecreasePercentTo,
        value: '0',
      };
      return viewLevel === ViewLevels.accountGroups
        ? createDecreaseToInstructionForAccountGroups(
            {
              type: InstructionAdjustmentType.DecreaseVolumeTo,
              value: '0',
              allocationType: AllocationType.ProRataDecreaseTo,
            } as SharedInstructionAccountGroupAdjustment,
            proposals as AccountGroupAdjustment[],
          )
        : createDecreaseToInstruction(adjustment, proposals as Adjustment[]);
    },
    [viewLevel],
  );

  const handleIncreasePositionInstruction = useCallback(
    async (
      proposals: (Adjustment | AccountGroupAdjustment)[],
      inputtedPercentage: string,
      selectedAllocation: string,
      excludedServiceTypes: string[],
    ) => {
      return viewLevel === ViewLevels.accountGroups
        ? createIncreaseToInstructionForAccountGroups(
            {
              type: InstructionAdjustmentType.IncreaseVolumeTo,
              value: inputtedPercentage,
              allocationType:
                selectedAllocation === 'pro-rata'
                  ? AllocationType.ProRataIncreaseTo
                  : AllocationType.EquallyIncreaseTo,
              excludeServiceTypes: excludedServiceTypes,
            } as SharedInstructionAccountGroupAdjustment,
            proposals as AccountGroupAdjustment[],
          )
        : createIncreaseToInstruction(
            {
              type: InstructionAdjustmentType.IncreasePercentTo,
              value: inputtedPercentage,
            } as SharedInstructionAdjustment,
            proposals as Adjustment[],
          );
    },
    [viewLevel],
  );

  const handleDecreasePositionInstruction = useCallback(
    async (
      proposals: (Adjustment | AccountGroupAdjustment)[],
      inputtedPercentage: string,
    ) => {
      return viewLevel === ViewLevels.accountGroups
        ? createDecreaseToInstructionForAccountGroups(
            {
              type: InstructionAdjustmentType.DecreaseVolumeTo,
              value: inputtedPercentage,
              allocationType: AllocationType.ProRataDecreaseTo,
            } as SharedInstructionAccountGroupAdjustment,
            proposals as AccountGroupAdjustment[],
          )
        : createDecreaseToInstruction(
            {
              type: InstructionAdjustmentType.DecreasePercentTo,
              value: inputtedPercentage,
            } as SharedInstructionAdjustment,
            proposals as Adjustment[],
          );
    },
    [viewLevel],
  );

  return {
    handleSellAllInstruction,
    handleIncreasePositionInstruction,
    handleDecreasePositionInstruction,
  };
};
