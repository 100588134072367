import { useAppDispatch } from '@app/app/hooks';
import {
  setSwitchToSecurities,
  setInputtedCurrentHolding,
  setIsAdditionalSwitchVisible,
} from '@app/features/Switch';
import {
  SharedSecurityDetailsResponse,
  SharedSwitchSecurity,
} from '@bsa/shared-types';
import { FormFieldStatus, InputKeyboardEvent } from '@iress/components-react';
import { useCallback } from 'react';

interface UseSwitchEventHandlersParams {
  switchToSecurities: SharedSwitchSecurity[];
  availableSecurities: SharedSecurityDetailsResponse[];
}

export const useSwitchEventHandlers = ({
  switchToSecurities,
  availableSecurities,
}: UseSwitchEventHandlersParams) => {
  const dispatch = useAppDispatch();

  const handleCurrentHoldingChange = useCallback(
    (event: InputKeyboardEvent) => {
      dispatch(setInputtedCurrentHolding(event.target.value));
    },
    [dispatch],
  );

  const handleSwitchSecurityChange = useCallback(
    (index: number, value: string | number | string[]) => {
      const selectedSwitchToSecurity = availableSecurities.find(
        (availableSecurity) => availableSecurity.securityId === Number(value),
      );

      const newSwitchToSecurity = {
        securityDetails: selectedSwitchToSecurity || '',
        percentage: switchToSecurities[index]?.percentage || '0',
      } as SharedSwitchSecurity;

      const updatedSwitchToSecurities = [...switchToSecurities];
      updatedSwitchToSecurities[index] = newSwitchToSecurity;

      dispatch(setSwitchToSecurities(updatedSwitchToSecurities));
    },
    [availableSecurities, dispatch, switchToSecurities],
  );

  const handleFirstSwitchSecurityChange = useCallback(
    ({ value }: FormFieldStatus<HTMLElement>) => {
      handleSwitchSecurityChange(0, value);
    },
    [handleSwitchSecurityChange],
  );

  const handleSecondSwitchSecurityChange = useCallback(
    ({ value }: FormFieldStatus<HTMLElement>) => {
      handleSwitchSecurityChange(1, value);
    },
    [handleSwitchSecurityChange],
  );

  const handleSwitchPercentageChange = useCallback(
    (index: number, event: InputKeyboardEvent) => {
      const newSwitchToSecurity = {
        securityDetails: switchToSecurities[index]?.securityDetails || '',
        percentage: event.target.value,
      } as SharedSwitchSecurity;

      const updatedSwitchToSecurities = [...switchToSecurities];
      updatedSwitchToSecurities[index] = newSwitchToSecurity;

      dispatch(setSwitchToSecurities(updatedSwitchToSecurities));
    },
    [dispatch, switchToSecurities],
  );

  const handleFirstSwitchPercentageChange = useCallback(
    (event: InputKeyboardEvent) => {
      handleSwitchPercentageChange(0, event);
    },
    [handleSwitchPercentageChange],
  );

  const handleSecondSwitchPercentageChange = useCallback(
    (event: InputKeyboardEvent) => {
      handleSwitchPercentageChange(1, event);
    },
    [handleSwitchPercentageChange],
  );

  const handleAddAnotherClick = useCallback(
    () => dispatch(setIsAdditionalSwitchVisible(false)),
    [dispatch],
  );

  const handleDeleteSecurityClick = useCallback(() => {
    dispatch(setIsAdditionalSwitchVisible(true));
    const updatedSwitchToSecurities = [...switchToSecurities];
    updatedSwitchToSecurities.splice(1, 1);
    dispatch(setSwitchToSecurities(updatedSwitchToSecurities));
  }, [dispatch, switchToSecurities]);

  return {
    handleAddAnotherClick,
    handleCurrentHoldingChange,
    handleDeleteSecurityClick,
    handleFirstSwitchPercentageChange,
    handleFirstSwitchSecurityChange,
    handleSecondSwitchPercentageChange,
    handleSecondSwitchSecurityChange,
  };
};
