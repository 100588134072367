import {
  SharedSwitchSecurity,
  SharedSwitchPayload,
  SharedSwitchResponse,
  SharedSwitchType,
  SharedSwitchAdjustment,
} from '../../sharedTypes';
import { ZERO, HUNDRED, THOUSAND, ONE } from '../constants';

export const sharedCreateMockSwitchAdjustment = (
  overrides: Partial<SharedSwitchAdjustment> = {}
): SharedSwitchAdjustment => {
  const account = overrides.account ?? 'account-description-1';
  const security = overrides.security ?? 'Vodafone';
  let hierarchy = overrides.hierarchy ?? [account, security];
  if (overrides.switchType === SharedSwitchType.UNKNOWN) {
    hierarchy = [account];
  }
  return {
    account,
    accountId: '123456',
    currentUnits: HUNDRED.toString(),
    hierarchy,
    'currentValue (CCY)': THOUSAND.toString(),
    portfolio: 'Johnson, Jon',
    proposedUnits: ZERO.toString(),
    'proposedValue (CCY)': ZERO.toString(),
    security,
    securityId: '123456',
    switchType: 'SELL' as SharedSwitchType,
    'targetValue (CCY)': THOUSAND.toString(),
    ...overrides,
  };
};

export const sharedMockSwitchBuyAdjustment = (
  overrides: Partial<SharedSwitchAdjustment> = {}
) =>
  sharedCreateMockSwitchAdjustment({
    switchType: SharedSwitchType.BUY,
    securityId: '123457',
    security: 'Microsoft',
    ...overrides,
  });

export const sharedMockSwitchSellAdjustment = (
  overrides: Partial<SharedSwitchAdjustment> = {}
) =>
  sharedCreateMockSwitchAdjustment({
    switchType: SharedSwitchType.SELL,
    securityId: '123455',
    security: 'Vodafone',
    ...overrides,
  });

export const sharedMockSwitchAccountAdjustment = (
  overrides: Partial<SharedSwitchAdjustment> = {}
) =>
  sharedCreateMockSwitchAdjustment({
    switchType: SharedSwitchType.UNKNOWN,
    securityId: '',
    proposedUnits: '',
    'proposedValue (CCY)': '',
    'targetValue (CCY)': '',
    currentUnits: '',
    'currentValue (CCY)': '',
    ...overrides,
  });

export const sharedMockSellSwitchSecurity: SharedSwitchSecurity = {
  percentage: '100',
  securityDetails: {
    securityId: 123456,
    code: 'VOD.LSE',
    exchange: 'LSE',
    description: 'Vodafone Group Plc',
    sedol: 'BH4HKS3',
    isin: 'GB00BH4HKS39',
    nativeCurrency: 'GBP',
    nativePrice: ONE.toString(),
    userCurrency: 'GBP',
    marketPrice: THOUSAND.toString(),
    assetClass: 'EQUITY',
    searchType: 'ISIN',
    allowPartialVolume: true,
  },
};

export const sharedMockBuySwitchSecurity: SharedSwitchSecurity = {
  percentage: '100',
  securityDetails: {
    securityId: 123457,
    code: 'FEVR.LSE',
    exchange: 'LSE',
    description: 'Microsoft Corporation',
    sedol: 'BRJ9BJ2',
    isin: 'GB00BRJ9BJ26',
    nativeCurrency: 'GBP',
    nativePrice: ONE.toString(),
    userCurrency: 'GBP',
    marketPrice: THOUSAND.toString(),
    assetClass: 'UK EQUITY',
    searchType: 'ISIN',
    allowPartialVolume: true,
  },
};

export const sharedMockSwitchPayload: SharedSwitchPayload = {
  adjustments: [
    sharedMockSwitchAccountAdjustment(),
    sharedMockSwitchSellAdjustment(),
  ],
  buySecurities: [sharedMockBuySwitchSecurity],
  sellSecurity: sharedMockSellSwitchSecurity,
};

export const sharedMockSwitchResponse: SharedSwitchResponse = {
  proposals: [
    sharedMockSwitchAccountAdjustment(),
    sharedMockSwitchSellAdjustment(),
    sharedMockSwitchBuyAdjustment(),
  ],
};
