import { getSelectedSwitchesRowIds } from '@app/utils/modifiers.utils';
import {
  SharedProposalResponse,
  SharedSwitchAdjustment,
  SharedSwitchSecurity,
} from '@bsa/shared-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createSwitchInstructionThunk,
  createSwitchProposalThunk,
} from './switchThunk';
import { ThunkLoadingStatus } from '@app/types/loadingStatus';

export interface SwitchState {
  applySwitchesLoadingStatus: ThunkLoadingStatus;
  generateSwitchProposalLoadingStatus: ThunkLoadingStatus;
  inputtedCurrentHolding: string;
  originalProposals: SharedSwitchAdjustment[];
  selectedSwitches: string[];
  switchProposalResponses: SharedProposalResponse;
  switchProposals: SharedSwitchAdjustment[];
  switchToSecurities: SharedSwitchSecurity[];
  isAdditionalSwitchVisible: boolean;
}

export const initialSwitchState: SwitchState = {
  applySwitchesLoadingStatus: {
    loading: 'idle',
    message: '',
  },
  generateSwitchProposalLoadingStatus: {
    loading: 'idle',
    message: '',
  },
  inputtedCurrentHolding: '0',
  originalProposals: [],
  selectedSwitches: [],
  switchProposalResponses: { proposals: [], failedProposals: [] },
  switchProposals: [],
  switchToSecurities: [] as SharedSwitchSecurity[],
  isAdditionalSwitchVisible: true,
};

export const SwitchSlice = createSlice({
  name: 'Switch',
  initialState: initialSwitchState,
  reducers: {
    setSwitchState: (state, action: PayloadAction<SwitchState>) => {
      Object.assign(state, action.payload);
    },
    resetSwitchState: (state) => {
      Object.assign(state, initialSwitchState);
    },
    setInputtedCurrentHolding: (
      state,
      action: PayloadAction<SwitchState['inputtedCurrentHolding']>,
    ) => {
      state.inputtedCurrentHolding = action.payload;
    },
    setOriginalProposals: (
      state,
      action: PayloadAction<SwitchState['originalProposals']>,
    ) => {
      state.originalProposals = action.payload;
    },
    setSwitchProposals: (
      state,
      action: PayloadAction<SwitchState['switchProposals']>,
    ) => {
      state.switchProposals = action.payload;
    },
    setSelectedSwitches: (
      state,
      action: PayloadAction<SwitchState['switchProposals']>,
    ) => {
      state.selectedSwitches = getSelectedSwitchesRowIds(action.payload);
    },
    setSwitchToSecurities: (
      state,
      action: PayloadAction<SwitchState['switchToSecurities']>,
    ) => {
      state.switchToSecurities = action.payload;
    },
    setIsAdditionalSwitchVisible: (
      state,
      action: PayloadAction<SwitchState['isAdditionalSwitchVisible']>,
    ) => {
      state.isAdditionalSwitchVisible = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSwitchInstructionThunk.pending, (state) => {
        state.applySwitchesLoadingStatus.loading = 'pending';
      })
      .addCase(createSwitchInstructionThunk.fulfilled, (state, action) => {
        state.applySwitchesLoadingStatus.loading = 'succeeded';
        state.switchProposals = action.payload.proposals;
        state.selectedSwitches = getSelectedSwitchesRowIds(
          action.payload.proposals,
        );
      })
      .addCase(createSwitchInstructionThunk.rejected, (state) => {
        state.applySwitchesLoadingStatus.loading = 'failed';
      })
      .addCase(createSwitchProposalThunk.pending, (state) => {
        state.generateSwitchProposalLoadingStatus.loading = 'pending';
      })
      .addCase(createSwitchProposalThunk.fulfilled, (state, action) => {
        state.generateSwitchProposalLoadingStatus.loading = 'succeeded';
        state.switchProposalResponses = action.payload;
      })
      .addCase(createSwitchProposalThunk.rejected, (state) => {
        state.generateSwitchProposalLoadingStatus.loading = 'failed';
      });
  },
});

export const {
  resetSwitchState,
  setInputtedCurrentHolding,
  setOriginalProposals,
  setSelectedSwitches,
  setSwitchProposals,
  setSwitchState,
  setSwitchToSecurities,
  setIsAdditionalSwitchVisible,
} = SwitchSlice.actions;
export default SwitchSlice.reducer;
