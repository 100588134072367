import { IressModal, IressText, IressInline } from '@iress/components-react';

/**
 * Modal to alert the user and perform actions
 * @returns Modal component
 */
function Modal(
  props: Readonly<{
    id: string;
    title: string;
    message: string;
    dataTestId: string;
    buttons?: React.ReactNode[];
  }>,
) {
  return (
    <IressModal
      id={props.id}
      size={IressModal.Size.Sm}
      data-testid={props.dataTestId}
    >
      <IressText element={IressText.Element.H2}>{props.title}</IressText>
      <IressText>
        <p>{props.message}</p>
      </IressText>
      <IressInline
        gutter={IressInline.Gutter.Md}
        horizontalAlign={IressInline.HorizontalAlign.Right}
      >
        {props.buttons}
      </IressInline>
    </IressModal>
  );
}
export default Modal;
