import { useMemo } from 'react';
import { useAppSelector } from '@app/app/hooks';
import { RootState } from '@app/app/store';
import {
  InputKeyboardEvent,
  FormFieldStatus,
  MenuItemMeta,
} from '@iress/components-react';
import { SelectedLevels, ViewLevels } from '@app/utils/constants';
import { useSwitchEventHandlers } from './useSwitchEventHandlers';
import {
  SharedSecurityDetailsResponse,
  SharedSwitchSecurity,
} from '@bsa/shared-types';
import { useComboBoxInputChange } from '@app/hooks';

interface SwitchState {
  states: {
    selectedLevel: string;
    inputtedCurrentHolding: string;
    selectedSecurity: SharedSecurityDetailsResponse;
    switchToSecurities: SharedSwitchSecurity[];
    noResultText: string;
    isAdditionalSwitchVisible: boolean;
  };
  handlers: {
    handleAddAnotherClick: () => void;
    handleDeleteSecurityClick: () => void;
    handleCurrentHoldingChange: (event: InputKeyboardEvent) => void;
    handleFirstSwitchSecurityChange: (
      event: FormFieldStatus<HTMLElement>,
    ) => void;
    handleSecondSwitchSecurityChange: (
      event: FormFieldStatus<HTMLElement>,
    ) => void;
    handleFirstSwitchPercentageChange: (event: InputKeyboardEvent) => void;
    handleSecondSwitchPercentageChange: (event: InputKeyboardEvent) => void;
    handleComboBoxInputChange: (query: string) => Promise<MenuItemMeta[]>;
  };
}

export const useSwitchState = (): SwitchState => {
  const viewLevel = useAppSelector(
    (state: RootState) => state.accountGroups.viewLevel,
  );
  const inputtedCurrentHolding = useAppSelector(
    (state: RootState) => state.switch.inputtedCurrentHolding,
  );
  const selectedSecurity = useAppSelector(
    (state: RootState) => state.securities.selectedSecurity,
  );
  const switchToSecurities = useAppSelector(
    (state: RootState) => state.switch.switchToSecurities,
  );
  const isAdditionalSwitchVisible = useAppSelector(
    (state: RootState) => state.switch.isAdditionalSwitchVisible,
  );

  const selectedLevel =
    viewLevel === ViewLevels.accountGroups
      ? SelectedLevels.accountGroup
      : SelectedLevels.account;

  const securitiesToExcludFromSearch = [];
  if (selectedSecurity.securityId) {
    securitiesToExcludFromSearch.push(selectedSecurity.securityId);
  }
  if (switchToSecurities.length > 0) {
    securitiesToExcludFromSearch.push(
      switchToSecurities[0].securityDetails?.securityId,
    );
  }

  const {
    handleComboBoxInputChange,
    securities: availableSecurities,
    noResultText,
  } = useComboBoxInputChange(securitiesToExcludFromSearch);

  const states = useMemo(
    () => ({
      selectedLevel,
      inputtedCurrentHolding,
      selectedSecurity,
      switchToSecurities,
      noResultText,
      isAdditionalSwitchVisible,
    }),
    [
      selectedLevel,
      inputtedCurrentHolding,
      selectedSecurity,
      switchToSecurities,
      noResultText,
      isAdditionalSwitchVisible,
    ],
  );

  const {
    handleAddAnotherClick,
    handleDeleteSecurityClick,
    handleCurrentHoldingChange,
    handleFirstSwitchSecurityChange,
    handleSecondSwitchSecurityChange,
    handleFirstSwitchPercentageChange,
    handleSecondSwitchPercentageChange,
  } = useSwitchEventHandlers({ switchToSecurities, availableSecurities });

  const handlers = useMemo(
    () => ({
      handleAddAnotherClick,
      handleDeleteSecurityClick,
      handleCurrentHoldingChange,
      handleFirstSwitchSecurityChange,
      handleSecondSwitchSecurityChange,
      handleFirstSwitchPercentageChange,
      handleSecondSwitchPercentageChange,
      handleComboBoxInputChange,
    }),
    [
      handleAddAnotherClick,
      handleDeleteSecurityClick,
      handleCurrentHoldingChange,
      handleFirstSwitchSecurityChange,
      handleSecondSwitchSecurityChange,
      handleFirstSwitchPercentageChange,
      handleSecondSwitchPercentageChange,
      handleComboBoxInputChange,
    ],
  );

  return useMemo(
    () => ({
      handlers,
      states,
    }),
    [handlers, states],
  );
};
