import { LoadingStatus } from '@app/features/AccountGroups';
import { deleteSavedSession } from '@app/services/progress';
import { HeadingLevel, showModal, toast } from '@iress/components-react';

export function handleGenerateProposalStatus(
  loadingStatus: LoadingStatus,
  setIsLoading: (value: boolean) => void,
  isSaveProgressEnabled: boolean,
  okModalId: string,
) {
  setIsLoading(false);
  if (loadingStatus === 'succeeded') {
    void deleteSavedSession();
    toast.success({
      headingText: 'Proposals generated',
      headingLevel: HeadingLevel.H3,
      content: 'Updates have been saved',
    });
    // wait for toastr before redirecting
    setTimeout(() => {
      window.location.hash = 'thehub/proposal_pad';
    }, 2000);
  } else if (loadingStatus === 'failed' && isSaveProgressEnabled) {
    showModal(okModalId, true);
  }
}
